import { createContext, useState, useEffect, useContext, useCallback, useMemo } from "react";

import { useTranslation, useTranslate, useAuth } from "contexts";
import { createGetRequest, isNonEmpty } from "Utils/Common";

const AppContext = createContext({});

export const useApp = () => useContext(AppContext);
export const AppProvider = ({ children }) => {
    const [ config, setConfig ] = useState({});
    const [ config2, setConfig2 ] = useState({});

    const translate = useTranslation();
    const { translations } = useTranslate();
    const { auth } = useAuth();

    useEffect(() => {
        if( auth.isAuth ){
            createGetRequest("get_account")
                .then(resp => resp.data)
                .then(resp => {
                    if( resp.success === true ) {
                        let data = resp.data;
                        data['amountDue'] = Math.round(data['amountDue'] * 100) / 100;
    
                        setConfig(data);
                    } else {
                        alert("Une erreur est survenue. Veuillez rafraichir la page.") ;
                    }
                })
                .catch(err => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuth])

    useEffect(() => {
        if( auth.isAuth ){
            createGetRequest("get_ExtConfig")
                .then(resp => resp.data)
                .then(resp => {
                    if( resp.success !== true ){
                        alert("Une erreur est survenue. Veuillez rafraichir la page.") ;
                    }
                    const fetchDataTranslated = translateExtConfig(resp.data);
                    setConfig2(fetchDataTranslated);
                })
                .catch(err => console.log(err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuth, translations])

    const mapToTranslate = useMemo(() => ({
        lastName: "NewMessageLastname",
        firstName: "NewMessageFirstname",
        birthDate: "MiscDateOfBirth",
        birthPlace: "MiscPlaceOfBirth",
        birthCountry: "MiscNativeCountry",

        socName: "NewMessageCompanyName",
        siren: "NewMessageRegistrationNumber",
        contactId: "NewMessageContactIdentity",
        contactFunction: "NewMessageContactFunction",

        rue: "NewMessageNumberAndStreet",
        compl: "NewMessageAdditionalInformation",
        postal: "NewMessageZipCode",
        city: "NewMessageCity",
        country: "NewMessageCountry",
        tel: "MiscTelephoneNumber",
        prefContact: "MiscContactPreferences",
        email: "MiscEmail"
    }), [])

    // const mapToTranslateMandatory = useMemo(() => ({
    //     "Raison sociale": "NewMessageCompanyName",
    //     "SIREN/SIRET": "NewMessageRegistrationNumber",
    //     "Téléphone": "MiscTelephoneNumber",
    //     "Adresse email": "MiscEmail",
    //     "Numéro et rue": "NewMessageNumberAndStreet",
    //     "Complément": "NewMessageAdditionalInformation",
    //     "Code postal": "NewMessageZipCode",
    //     "Ville": "NewMessageCity",
    //     "Pays": "NewMessageCountry"
    // }), [])

    const mapToTranslateTypes = useMemo(() => ({
        pro: "NewMessageProfessional",
        part: "NewMessageIndividual"
    }), [])

    const translateExtConfig = useCallback(( fetchData ) => {
        let tempData = {};

        if( isNonEmpty(fetchData) ){
            fetchData = Object.entries(fetchData);
            fetchData.forEach(([key, val]) => {
                switch( key ) {
                    case 'pro' :
                    case 'part' :
                    case 'both' :
                        val.forEach(el => {
                            let { code } = el;

                            el.displayName = translate(mapToTranslate[code]);
                            el.labelName = translate(mapToTranslate[code]);

                            return el;
                        })
                        break ;
                    // case 'mandatory' : 
                    //     val.forEach((el, i) => {
                    //         return val[i] = translate(mapToTranslateMandatory[el]);
                    //     })
                    //     break ;
                    case 'types' : 
                        val.forEach(el => {
                            let { code } = el;

                            el.displayName = translate(mapToTranslateTypes[code]);

                            return el;
                        })
                        break ;
                    default : break ;
                }
            })
            fetchData = Object.fromEntries(fetchData);
            return fetchData;
        }

        return tempData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translations])

    return <AppContext.Provider value={{ config, config2 }}>{ children }</AppContext.Provider>;
}