import React, { useState } from "react";

import { Field, Form, Formik } from "formik";
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from "yup";

import { InputWithFeedback } from "components";
import { useTranslate, useTranslation, useApp } from "contexts";
import { createPostRequest, isNonEmpty } from "Utils/Common";

import UploadComponent from "./UploadComponent";

import AddCommentModal from "views/Recouveo/NouveauMessage/Modals/AddCommentModal";
import ConfirmModal from "views/Recouveo/NouveauMessage/Modals/ConfirmModal";
import ErrorModal from "views/Recouveo/Modals/ErrorModal";

import PaymentBlock from "./_PaymentBlock";
import ContactDetailBlock from "./_ContactDetailBlock";

const Paid = ({ ...props }) => {
	const { uploadFiles, addFiles } = props;

	const [ files, setFiles ] = useState([]);
	const [ showModal, setShowModal ] = useState(false);
	const [ currentFile, setCurrentFile ] = useState(null);
	const [ showError, setErr ] = useState(false);
	const [ errorTxt, setErrTxt ] = useState(null);
	const [ showConfirmModal, setConfirmModal ] = useState(false);

    const { lang } = useTranslate();
    const translate = useTranslation();
    const { config } = useApp();

	const addFilesToState = (e) => {
		setFiles(addFiles(e, files));
	}

	const setFileNewName = (name, i) => {
		if (files[i] === undefined || files[i] === null) return;
		files[i].newName = name;
	}
    
    const validationSchema = Yup.object().shape({
        tel: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)$", translate("MiscTelephoneNumberMustBeValid")),
        email: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$", translate("MiscEmailAddressMustBeValid")),
        dueSum: Yup
            .number()
            .required( translate("MiscFieldRequired") )
            .min(1, translate("MiscValueMustBeGreaterThan") + " 0")
            .typeError(translate("MiscFieldMustBeNumeric") 
            + " " + translate("MiscForDecimalsUse")),
        dueWithRadios: Yup
            .string()
            .required( translate("MiscFieldRequired") )
    })

	return (
        <>
            {
                isNonEmpty(config) &&
                    <div className={"form"}>
                        <Formik
                            initialValues={{
                                dueSum: "",
                                dueDate: "",
                                dueToRadios: "",
                                dueWithRadios: "",
                                bankAccountNumber: "",
                                name: config["accName"],
                                newName: "",
                                siren: config["siren"],
                                tel: config["tel"].replace(/\D/g, ''),
                                email: config["email"],
                                comment: "",
                                prefContact: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                let title = translate("NewMessageAlreadyPaid");
                                let paid;
                                values.dueToRadios === 0 ? paid = translate("MiscPaid") + " " + translate("NewMessageToRecouveo") : paid = translate("MiscPaid") + " " + translate("NewMessageToCreditor");
                                let text =
                                    translate("MiscAmountPaid") + ": " + values.dueSum + "€ \r\n" +
                                    paid + "\r\n" +
                                    translate("MiscPaid") + " " + translate("MiscBy").toLowerCase() + ": " + values.dueWithRadios + "\r\n" +
                                    translate("NewMessageContactIdentityCompleted") + ": " + values.newName + "\r\n" +
                                    translate("MiscTelephoneNumber") + ": " + values.tel + "\r\n" +
                                    translate("MiscContactPreferencesShort") + ": " + values.prefContact + "\r\n" +
                                    translate("MiscEmail") + ": " + values.email + "\r\n" +
                                    translate("MiscMessage") + ": " + values.comment + "\r\n";
                                if (values.dueDate !== "") {
                                    text += translate("MiscPaymentDate") + ": " + new Date(values.dueDate).toLocaleDateString(lang) + "\r\n";
                                }
                                if (props.factSelected.length > 0){
                                    text += translate("NewMessageAffectedInvoices") + ": " ;
                                    props.factSelected.forEach((rec) => {
                                        text += rec["record_ref"] + ", " ;
                                    })
                                    text = text.slice(0, -2) ;
                                    text += "\r\n" ;
                                }
                                let error = false;
                                if (files.length > 0) {
                                    uploadFiles(files).then(response => {
                                        response.forEach((res, i) => {
                                            if (res.data !== null && res.data.success !== true) {
                                                error = true;
                                            }
                                        });
                                        if (error === true) {
                                            props.formSent("-1");
                                            return;
                                        }
                                        text = text + translate("NewMessageAttachments") + ": \r\n";
                                        files.forEach((file, i) => {
                                            if (file.newName !== "") text = text + file.newName + "\r\n";
                                            else text = text + file.name + "\r\n";

                                        });
                                        let msgAction = {
                                            "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                            "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                        };
                                        createPostRequest("tchat_post_formulaire", {
                                            "file_filerecord_id": config["fileOpenFilerecordId"],
                                            "data": JSON.stringify(msgAction)
                                        }).then(response => {
                                            if (response.data.success !== true) {
                                                props.formSent("-1");
                                            } else props.formSent("7");

                                        })

                                    }).catch(err => {
                                        error = true;
                                        props.addResult(false);
                                        props.formSent("-1");
                                    });
                                } else {
                                    let msgAction = {
                                        "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                        "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                        "file_filerecord_id": config["fileOpenFilerecordId"],
                                        "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                        if (response.data.success !== true) {
                                            props.formSent("-1");
                                        } else props.formSent("7");
                                    })
                                }
                            }}
                            onReset={values => props.onReset()}
                        >
                        {({
                            setFieldValue,
                            submitForm
                        }) => (
                            <Form>
                                <AddCommentModal 
                                    isOpen={showModal} 
                                    content={currentFile !== null ? files[currentFile].comment : ""}
                                    toggle={() => setShowModal(!showModal)}
                                    onChange={(e) => {
                                        if (e === null) {
                                            setShowModal(false);
                                            return;
                                        }
                                        files[currentFile].comment = e.target.value;
                                    }}
                                />
                                <ErrorModal 
                                    isOpen={showError} 
                                    error={errorTxt} 
                                    toggle={() => setErr(!showError)}
                                />
                                <ConfirmModal 
                                    isOpen={showConfirmModal} 
                                    toggle={() => setConfirmModal(!showConfirmModal)}
                                    validate={() => {
                                        setConfirmModal(false);
                                        submitForm();
                                    }}
                                />
                                <Row>
                                    <Col xs="6">
                                        <FormGroup row>
                                            <Col xs="6">
                                                <Label className="control-label">{ translate("NewMessageAlreadyPaidAmountOf") }</Label>
                                            </Col>
                                            <Col xs="6">
                                                <Field required="required" component={InputWithFeedback} type="text" name="dueSum"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col xs="6">
                                                <Label>{ translate("NewMessagePaymentDate") }</Label>
                                            </Col>
                                            <Col xs="6">
                                                <Field component={InputWithFeedback} type="date" name="dueDate"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col xs="6">
                                                <Label>{ translate("NewMessageToWhom") }</Label>
                                            </Col>
                                            <Col xs="6">
                                                <FormGroup check className="radio">
                                                    <Field component={InputWithFeedback} type="radio" id="dueTo1" name="dueToRadios" value="0"/>
                                                    <Label check className="form-check-label">{ translate("NewMessageToRecouveo") }</Label>
                                                </FormGroup>
                                                <FormGroup check className="radio">
                                                    <Field component={InputWithFeedback} type="radio" id="dueTo2" name="dueToRadios" value="1"/>
                                                    <Label check className="form-check-label">{ translate("NewMessageToCreditor") }</Label>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                        <PaymentBlock name="dueWithRadios" />
                                        <ContactDetailBlock />
                                    </Col>
                                    <UploadComponent 
                                        files={files} 
                                        errors={showError} 
                                        addFiles={(e) => {
                                            if (e.target.files.length > 3 || files.length + e.target.files.length > 3) {
                                                setErrTxt( translate("NewMessageModalTooMuchFiles") );
                                                setErr(true);
                                                return;
                                            }
                                            let issue = false;
                                            const filesUploaded = Object.values(e.target.files);
                                            filesUploaded.forEach((file, i) => {
                                                if (file.size >= 10000000) {
                                                    setErrTxt( translate("NewMessageModalTooMuchSize") )
                                                    setErr(true);
                                                    issue = true;
                                                }
                                            });
                                            if (issue === true) return;
                                            addFilesToState(e);
                                            setFieldValue("files", files);
                                        }} 
                                        setFileNewName={setFileNewName} 
                                        setFiles={setFiles} 
                                        setFieldValue={setFieldValue}
                                        setShowModal={setShowModal} 
                                        setCurrentFile={setCurrentFile}
                                        currentFile={currentFile}
                                    /> 
                                </Row>
                                <Row>
                                    <div style={{width: '100%', margin: '0px auto', marginTop: '10px'}} className="text-center">
                                        <div style={{color: "red"}}>{ translate("MiscFieldsRequired") }</div>
                                        <br/>
                                        <Button 
                                            color="primary" 
                                            onClick={() => setConfirmModal(true)}
                                        >
                                            { translate("MiscSendMessage") }
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        )}
                        </Formik>
                    </div>
            }
        </>
	)
}

export default Paid;