import React, {Component} from 'react';
import {
    // Redirect
    Navigate
} from "react-router-dom";

class AccueilAferPay extends Component {
	render() {
		return (<Navigate to={{ pathname: "/accueil", state: { from: this.props.location } }} />) ;
	}
}

export default AccueilAferPay ;
