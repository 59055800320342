import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { 
    Alert, 
    Button, 
    Card, 
    CardBody, 
    CardHeader, 
    Input, 
    Modal, 
    ModalBody, 
    Row,
    Col, 
    FormGroup, 
    ModalHeader
} from 'reactstrap';

import Paid from './Forms/Paid';
import Delay from './Forms/Delay';
import CustomMessage from './Forms/CustomMessage';
import AskDocument from './Forms/AskDocument';
// import ModifyInfos2 from './Forms/ModifyInfos2';
import ModifyInfos3 from './Forms/ModifyInfos3';
import ContactMe from './Forms/ContactMe';

import Table from "views/Recouveo/Accueil/AccueilTable";

import { useTranslation, useApp } from "contexts";
import { isNonEmpty } from "Utils/Common";
import { instance as axios } from "Utils/axiosConfig";

const fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
    array.push(list.item(i));
    }
    return array;
} ;

const addFiles = (e, files) => {
    const eventFiles = e.target.files;
    if (eventFiles === null) return ;
    const array = fileListToArray(eventFiles);
    array.forEach((file, i) => {
    file.newName = "" ;
    })
    if (files === null){
    files = array ;
    return ;
    }
    let tmp = [...files, ...array] ;
    return tmp ;
}

const uploadFiles = (files) => {
  const promises = [];
  files.forEach(file => {
    promises.push(sendFile(file));
  });
  return Promise.all(promises) ;

}

const sendFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("desc", file.comment !== undefined ? file.comment : "") ;
  formData.append("newName", file.newName !== undefined ? file.newName : "") ;
  return axios({
    url: "/tchat_post_file",
    method: "POST",
    timeout: 8000,
    withCredentials: true,
    headers: {
      "Content-Type": 'multipart/form-data',
    },
    data: formData
  })
}

function NouveauMessage(props) {
    const [ mode, setMode ] = useState("0") ;
    const [ results, setResult ] = useState([]) ;
    const [ factSelected, setFactSelected ] = useState([]) ;
    const [ showModal, setShowModal ] = useState(false) ;
    // eslint-disable-next-line
    const [ factAmountSelected, setFactAmountSelected ] = useState(0);

    const { config } = useApp();
    const location = useLocation();
    const translate = useTranslation();
    
    useEffect(() => {
        if( location?.state !== null && location?.state !== undefined ){
            if (location.state.from === "informations") setMode("4") ;
            if (location.state.factSelected !== undefined && location.state.factSelected !== null && location.state.factSelected.length > 0){
                setFactSelected(location.state.factSelected) ;
            }
        }
    }, [location?.state]) ;

    // console.log(factSelected)

    useEffect(() => {
        if( isNonEmpty(config) && config["email"] !== "" ){
            config["email"] = config["email"].toLowerCase() ;
        }
    }, [config])
  
    const addResult = (err) => {
        let newErr = [...results, ...[err]] ;
        setResult(newErr) ;
    }

    const formSent = (mode) => {
        setMode(mode) ;
    }

    const payload = {
        factSelected,
        uploadFiles,
        addResult,
        addFiles,
        formSent
    };

    const renderSwitch = () => {
        switch (mode) {
            case "-1" : return <Alert color="danger">{ translate("NewMessageFailedToSend") }</Alert>;
            case "1" : return <Paid {...payload} />;
            case "2" : return <Delay {...payload} />;
            case "3" : return <CustomMessage {...payload} />;
            case "4" : return <ModifyInfos3 {...payload} />;
            case "5" : return <AskDocument {...payload} />;
            case "6" : return <ContactMe {...payload} />;
            case "7" : return <Alert color="primary">{ translate("NewMessageSentSuccessfully") }</Alert>;
            default : return null ;
        }
    }

    const renderFactModal = ({ ...props }) => {
        return (
            <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
                <ModalHeader style={{display: "unset", border: "none"}}>
                    <div className="userInfosAddInfos">
                        <Button onClick={props.toggle} color="danger">
                            <span style={{ color: "white", fontWeight: "bold" }}>X</span>
                        </Button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Table records={props.records} resetAmount={props.resetAmount} isPopup={true} />
                </ModalBody>
            </Modal>
        ) ;
    }

    return (
        <>
            <div className="animated fadeIn">
                {  
                    renderFactModal({
                        records: factSelected,
                        resetAmount: () => setFactAmountSelected(0),
                        isOpen: showModal,
                        toggle: () => setShowModal(!showModal)
                    }) 
                }
                {/* <FactModal isOpen={showModal} toggle={() => setShowModal(!showModal)} factSelected={factSelected}/> */}
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={7}>
                                <div className="userInfosTitleHeader">
                                    <strong>{ translate("MiscNewMessage") }</strong>
                                </div>
                            </Col>
                            <Col xs={5}>
                                <Input 
                                    type="select"
                                    value={mode}
                                    onChange={ e => setMode(e.target.value) }
                                >
                                    <option disabled={true} value="0">{ translate("NewMessageSelectAnOption") }</option>
                                    <option value="1">{ translate("NewMessageAlreadyPaid") }</option>
                                    <option value="2">{ translate("NewMessagePaymentSchedule") }</option>
                                    <option value="3">{ translate("NewMessageWishToSendYouAMessage") }</option>
                                    <option value="4">{ translate("NewMessageModifyMyContactDetails") }</option>
                                    <option value="5">{ translate("NewMessageNeedSomeDocuments") }</option>
                                    <option value="6">{ translate("NewMessageWouldLikeToBeContacted") }</option>
                                </Input>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {
                            factSelected.length > 0 
                            ?
                                <FormGroup row>
                                    <Col xs="6">
                                        <Button 
                                            color="success" 
                                            onClick={() => setShowModal(true)}
                                        >
                                            { translate("NewMessageDisplayInvoices") }
                                        </Button>
                                    </Col>
                                </FormGroup>
                            : 
                                null
                        }
                        { renderSwitch() }
                    </CardBody>
                </Card>
            </div>
        </>
    );
}

export default NouveauMessage;