import React from 'react';

import { 
    Button, 
    ButtonGroup, 
    Col, 
    FormGroup, 
    Input,
    Label, 
    Row 
} from "reactstrap";

import { useTranslation } from "contexts";
import { isEmpty } from "Utils/Common";

const UploadComponent2 = ({ ...props }) => {
    const { 
        msgRequired,
        files,
        onChange,
        onAddFiles,
        currentFile,
        setCurrentFile,
        setShowCommentModal,
        setFiles
    } = props;

    const translate = useTranslation();

    return (
        <>
            <FormGroup>
                <Label for="msg-textarea">{ translate("MiscMessage") }</Label>
                { msgRequired && <span style={{ color: 'red' }}>*</span> }
                <Input 
                    id='msg-textarea'
                    type='textarea'
                    name='comment'
                    style={{ height: '250px' }}
                    onChange={({ currentTarget }) => onChange(currentTarget.name, currentTarget.value)}
                />
            </FormGroup>
            <FormGroup row>
                <Col xs={12}>
                    <Label for="file-multiple-input">{ translate("MiscSubmitDocument") }</Label>
                </Col>
                <Col xs={12}>
                    <Label for="file-multiple-input" className="m-0">{ translate("MiscSelectFile") }</Label>
                    <span className="mx-2">|</span>
                    {
                            isEmpty(files)
                            ?
                                <Label for="file-multiple-input" className="m-0">{ translate("MiscNoFileSelected") }</Label>
                            :
                                <Label for="file-multiple-input" className="m-0">{ files.length + " " + translate("MiscFileSelected") }</Label>
                    }
                    <Input 
                        id="file-multiple-input"
                        type='file'
                        name='file-multiple-input'
                        multiple
                        style={{ height: "1px", padding: "0", visibility: "hidden" }}
                        onChange={onAddFiles}
                    />
                </Col>
            </FormGroup>
            {
                files.map((file, i) => (
                    <Row className="mt-2" key={"file-input-" + i}>
                        {/* <Col key={"fileInputCol-" + i} xs="8"> */}
                        <Col xs="8">
                            <Input 
                                value={file.newName !== "" ? file.newName : file.name}
                                name={"fileInput-" + i}
                                disabled
                                onChange={({ currentTarget }) => {}}
                            />
                        </Col>
                        <Col xs="3">
                            <ButtonGroup className="mr-2">
                                <Button 
                                    size="sm" 
                                    style={{ minWidth: '0' }} 
                                    color="primary"
                                    onClick={() => {
                                        setCurrentFile(i);
                                        setShowCommentModal(true);
                                    }}
                                >
                                    <i className="fa fa-info fa-lg"/>
                                </Button>
                                <Button 
                                    size="sm" 
                                    style={{ minWidth: '0' }} 
                                    color="danger" 
                                    onClick={() => {
                                        const newFiles = [...files];
                                        const index = newFiles.indexOf(file);
                                        if( index > -1 ) {
                                            newFiles.splice(index, 1);
                                            setFiles(newFiles);
                                            if( index === currentFile ){
                                                setCurrentFile(null) ;
                                            }
                                        }
                                    }
                                }>
                                    <i className="fa fa-close fa-lg"/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                ))
            }
        </>
    );
}

export default UploadComponent2;