import React, { useState } from "react";

import { Form, Formik } from "formik";
import { Button, Col, Row } from 'reactstrap';
import * as Yup from "yup";

import { useTranslation, useApp } from "contexts";
import { createPostRequest, isNonEmpty } from "Utils/Common";

import UploadComponent from "./UploadComponent";
import AddCommentModal from "views/Recouveo/NouveauMessage/Modals/AddCommentModal";
import ConfirmModal from "views/Recouveo/NouveauMessage/Modals/ConfirmModal";
import ErrorModal from "views/Recouveo/Modals/ErrorModal";

import ContactDetailBlock from "./_ContactDetailBlock";

const ContactMe = ({ ...props }) => {
    const { factSelected, uploadFiles, addResult, addFiles, formSent } = props;

    const [ files, setFiles ] = useState([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ currentFile, setCurrentFile ] = useState(null);
    const [ showError, setErr ] = useState(false);
    const [ errorTxt, setErrTxt ] = useState(null);
    const [ showConfirmModal, setConfirmModal ] = useState(false);

    const translate = useTranslation();
    const { config } = useApp();

    const addFilesToState = (e) => {
        setFiles(addFiles(e, files));
    }

    const setFileNewName = (name, i) => {
        if (files[i] === undefined || files[i] === null) return;
        files[i].newName = name;
    }

    const currentDate = new Date() ;
    let dateMax = new Date() ;
    dateMax.setDate(dateMax.getDate() + 7);

    const validationSchema = Yup.object().shape({
        tel: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)$",translate("MiscTelephoneNumberMustBeValid")),
        email: Yup
            .string()
            .required( translate("MiscFieldRequired") )
            .matches("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$", translate("MiscEmailAddressMustBeValid")),
        prefDay: Yup
            .date()
            .required( translate("MiscFieldRequired") )
            .min(currentDate, translate("MiscDateEarlierThanCurrent"))
            .max(dateMax, translate("MiscAppointmentNextSevenDays")),
        comment: Yup
            .string()
            .required( translate("MiscFieldRequired") )
    })

    return (
        <>
            {
                isNonEmpty(config) &&
                    <div className={"form"}>
                        <Formik
                            initialValues={{
                                name: config["accName"],
                                newName: "",
                                email: config["email"],
                                tel: config["tel"].replace(/\D/g,''),
                                prefDay: "",
                                prefHour: "",
                                comment: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                let title = translate("NewMessageWouldLikeToBeCalled");
                                let text =
                                    translate("NewMessageContactIdentityCompleted") + ": " + values.newName + "\r\n" +
                                    translate("MiscTelephoneNumber") + ": " + values.tel + "\r\n" +
                                    translate("NewMessageDate") + ": " + values.prefDay + "\r\n" +
                                    translate("NewMessageTimeSlot") + ": " + values.prefHour + "\r\n" +
                                    translate("MiscEmail") + ": " + values.email + "\r\n" +
                                    translate("MiscMessage") + ": " + values.comment + "\r\n";
                                if( isNonEmpty(factSelected) ){
                                    text += translate("NewMessageAffectedInvoices") + ": " ;
                                    factSelected.forEach((rec) => {
                                    text += rec["record_ref"] + ", " ;
                                    })
                                    text = text.slice(0, -2) ;
                                    text += "\r\n" ;
                                }
                                let error = false ;

                                if (files.length > 0) {
                                    uploadFiles(files).then(response => {
                                    response.forEach((res, i) => {
                                        if (res.data !== null && res.data.success !== true) {
                                        error = true ;
                                        }
                                    });
                                    if (error === true){
                                        formSent("-1") ;
                                        return ;
                                    }
                                    text = text + translate("NewMessageAttachments") + ": \r\n";
                                    files.forEach((file, i) => {
                                        if (file.newName !== "") text = text + file.newName + "\r\n";
                                        else text = text + file.name + "\r\n";

                                    });
                                    let msgAction = {
                                        "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                        "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                        "file_filerecord_id": config["fileOpenFilerecordId"],
                                        "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                        if (response.data.success !== true) {
                                            formSent("-1") ;
                                        } else formSent("7") ;

                                    })

                                    }).catch(err => {
                                        error = true ;
                                        addResult(false) ;
                                        formSent("-1");
                                    });
                                } else {
                                    let msgAction = {
                                    "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                    "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                    };
                                    createPostRequest("tchat_post_formulaire", {
                                    "file_filerecord_id": config["fileOpenFilerecordId"],
                                    "data": JSON.stringify(msgAction)
                                    }).then(response => {
                                    if (response.data.success !== true) {
                                        formSent("-1") ;
                                    } else formSent("7") ;
                                    })
                                }
                                let msgAction = {
                                    "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                                    "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                                };
                                createPostRequest("tchat_post_formulaire", {
                                    "file_filerecord_id": config["fileOpenFilerecordId"],
                                    "data": JSON.stringify(msgAction)
                                }).then(response => {
                                    if (response.data.success !== true) {
                                        formSent("-1");
                                    } else formSent("7");
                                })
                            }}
                            onReset={values => props.onReset()}
                        >
                            {({
                                setFieldValue,
                                submitForm,
                                values
                            }) => (
                                <Form>
                                    <AddCommentModal 
                                        isOpen={showModal} 
                                        content={currentFile !== null ? files[currentFile].comment : ""} 
                                        toggle={() => setShowModal(!showModal)}
                                        onChange={(e) => {
                                            if (e === null) {
                                                setShowModal(false);
                                                return;
                                            }
                                            files[currentFile].comment = e.target.value;
                                        }}
                                    />
                                    <ConfirmModal 
                                        isOpen={showConfirmModal} 
                                        toggle={() => setConfirmModal(!showConfirmModal)} 
                                        validate={() => {
                                            setConfirmModal(false) ;
                                            submitForm() ;
                                        }}
                                    />
                                    <ErrorModal isOpen={showError} error={errorTxt} toggle={() => setErr(!showError)}/>
                                    <Row>
                                        <Col xs="6">
                                            <ContactDetailBlock variant />
                                        </Col>
                                        <UploadComponent files={files} msgRequired={true} errors={showError} addFiles={(e) => {
                                            if (e.target.files.length > 3 || files.length + e.target.files.length > 3) {
                                            setErrTxt( translate("NewMessageModalTooMuchFiles") );
                                            setErr(true);
                                            return;
                                            }
                                            let issue = false;
                                            const filesUploaded = Object.values(e.target.files);
                                            filesUploaded.forEach((file, i) => {
                                            if (file.size >= 10000000) {
                                                setErrTxt( translate("NewMessageModalTooMuchSize") )
                                                setErr(true);
                                                issue = true;
                                            }
                                            });
                                            if (issue === true) return;
                                            addFilesToState(e);
                                            setFieldValue("files", files);
                                        }} setFileNewName={setFileNewName} setFiles={setFiles} setFieldValue={setFieldValue}
                                                        setShowModal={setShowModal} setCurrentFile={setCurrentFile}
                                                        currentFile={currentFile}/>
                                    </Row>
                                    <Row>
                                        <div style={{width: '100%', margin: '0px auto', marginTop: '10px'}} className="text-center">
                                            <div style={{color: "red"}}>{ translate("MiscFieldsRequired") }</div>
                                            <br/>
                                            <Button 
                                                color="primary" 
                                                onClick={() => {
                                                    if (values.comment === ""){
                                                        setErrTxt( translate("NewMessageFillMessageField") ) ;
                                                        setErr(true) ;
                                                        return ;
                                                    }
                                                    setConfirmModal(true) ;
                                                }}
                                            >
                                                { translate("MiscSendMessage") }
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </>
  )
}
export default ContactMe;