import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { Loading } from "components";
import { useTranslation, useApp } from "contexts";
import { isNonEmpty, isEmpty } from 'Utils/Common';

import Payer from "./Modal/Payer";
import Table from './AccueilTable';

import './Accueil.css';

const Accueil = ({ ...props }) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalAfterpay, setShowModalAfterpay ] = useState(false);
    const [ factAmountSelected, setFactAmountSelected ] = useState(0);
    const [ factSelected, setFactSelected ] = useState([]);

    const navigate = useNavigate();
    const translate = useTranslation();
    const { config } = useApp();
    const { accueilParam1, accueilParam2 } = useParams();

    useEffect(() => {
        if( accueilParam1 && accueilParam1 === "afterpay" ) {
            navigate("/accueil") ; //HACK
            //TODO : remove query ? 
            setShowModal(true);
            setShowModalAfterpay(accueilParam2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accueilParam1, accueilParam2])

    const handleModal = ( torf ) => {
        let newState ;

        if( torf != null ) {
           newState = torf ;
        } else {
            newState = !showModal ;
        }

        setShowModal(newState);
        setShowModalAfterpay(false);
    }

    const handleModalOpen = () => {
		handleModal(true) ;
    }

    const handleModelEnd = ( payerStatus ) => {
        handleModal(false) ;
    }

    const onRowSelection = ( isSelected, row, isMultiple ) => {
        let amount = 0;
        let indexes = [] ;

        if (isMultiple === true){
            row.forEach((rec) => {
                let tmpAmount = rec.amount.toString().replace(/\s/g,'') ;
                tmpAmount = tmpAmount.replace(",",'.') ;
                tmpAmount = parseFloat(tmpAmount) ;
                amount += tmpAmount ;
            })
        } else {
            amount = row.amount.toString().replace(/\s/g,'') ;
            amount = amount.replace(",",'.') ;
            amount = parseFloat(amount) ;
        }

        if( amount <= 0 ) return ;

        if( isSelected === true ) {
            let res = factAmountSelected + amount ;
            res = parseFloat(res.toFixed(2)) ;

            let newFactSelect = [...factSelected] ;

            if( isMultiple === true ){
                row.forEach((rec) => {
                    newFactSelect.push(rec) ;
                }) ;
            } else {
                newFactSelect.push(row) ;
            }

            setFactAmountSelected(res);
            setFactSelected(newFactSelect);
        } else {
            if( amount < 0 ) amount = 0 ;

            let res = factAmountSelected - amount ;
            res = parseFloat(res.toFixed(2)) ;

            let newFactSelect = [...factSelected] ;
            let index ;

            newFactSelect.forEach((fact, i) => {
                if( isMultiple === true ) {
                    row.forEach((rec) => {
                        if( fact["record_ref"] === rec["record_ref"] ) {
                            indexes.push(i) ;
                        }
                    })
                } else {
                    if( fact["record_ref"] === row["record_ref"] ) {
                        index = i ;
                    }
                }
            })

            if( isMultiple !== true ) {
                newFactSelect.splice(index, 1) ;
            } else {
                while( indexes.length ) {
                    newFactSelect.splice(indexes.pop(), 1);
                }
            }

            setFactAmountSelected(res);
            setFactSelected(newFactSelect);
        }
    }

    const resetAmount = () => {
        setFactAmountSelected(0);
    }

    return (
        <>
            {
                isNonEmpty(config)
                ?
                    <div className="animated fadeIn">
                        <Row>
                            <Col className="col-accueil" xs="3">
                                <Card 
                                    style={{
                                        minHeight: '140px',
                                        border: '1px solid #2eadd3',
                                        backgroundColor: '#63c2de',
                                        color: 'white',
                                        margin: '10px 0'
                                    }}
                                >
                                    <CardBody>
                                        <div 
                                            style={{
                                                fontSize: '1.5rem', 
                                                fontWeight: '600'
                                            }}
                                        >
                                            { translate("HomeDueAmount") } (€)
                                        </div>
                                        
                                        <div 
                                            style={{
                                                fontWeight: '600', 
                                                display: 'flex'
                                            }}
                                        >
                                            <div 
                                                style={{
                                                    margin: 'auto', 
                                                    fontSize: '2.2rem'
                                                }}
                                            >
                                                {/* {new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(config["amountDue"])} */}
                                                { config["amountDue"] }
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-accueil" xs="6">
                                <Card 
                                    style={{
                                        minHeight: '140px',
                                        border: '1px solid #379457',
                                        backgroundColor: '#4dbd74',
                                        color: 'white',
                                        margin: '10px 0'
                                    }}
                                >
                                    <CardBody
                                        style={{
                                            padding: '20px'
                                        }}
                                    >
                                        <table style={{width: '100%'}} className="accueil-table">
                                            <tbody>
                                                <tr>
                                                    <td className="accueil-td" style={{ fontSize: '1.2rem', fontWeight: '600'}}>{ translate("HomeYourCreditor") }</td>
                                                    <td className="accueil-td-text" style={{ fontSize: '1.2rem', fontWeight: '600'}}>{ config["socTxt"] }</td>
                                                </tr>
                                                <tr>
                                                    <td className="accueil-td" style={{ fontSize: '1.2rem', fontWeight: '600'}}>{ translate("HomeYourClientRef") }</td>
                                                    <td className="accueil-td-text" style={{ fontSize: '1.2rem', fontWeight: '600'}}>{ config["accId"] }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-accueil" xs="3" >
                                <Card 
                                    style={{
                                        minHeight: '140px',
                                        border: '0',
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        margin: '10px 0'
                                    }}
                                >
                                    <div style={{height: '100%', display: 'flex', textAlign: 'center'}}>
                                        <div style={{margin: 'auto'}}>
                                            <div style={{width: '100%'}}>
                                                <Button color="primary" size="lg" onClick={handleModalOpen}>
                                                    <div>{ translate("HomePayment") }</div>
                                                    { 
                                                        factAmountSelected > 0 
                                                        ? 
                                                            <div>
                                                                { "(" + new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(factAmountSelected) + " €)" }
                                                            </div>
                                                        : 
                                                            null
                                                    }
                                                </Button>
                                            </div>
                                            <div style={{width: '100%'}} id="nouveau-message-accueil">
                                                <Button color="primary" size="lg" onClick={() => {
                                                    if( factSelected.length === 0 ) {
                                                        navigate("/nouveauMessage");
                                                    } else {
                                                        navigate("/nouveauMessage", { state: { factSelected } });
                                                    }
                                                }}>
                                                    { translate("MiscNewMessage") }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Payer 
                                isOpen={showModal} 
                                onModalEnd={handleModelEnd}
                                afterPay={!!showModalAfterpay} 
                                afterPayCode={showModalAfterpay}
                                accId={config["accId"]}
                                payAmount={
                                    factAmountSelected > 0 
                                    ?
                                        factAmountSelected 
                                    : 
                                        config["amountDue"]
                                }
                            />
                        </Row>
                        <Row>
                            <Card>
                                <CardHeader>
                                    <strong>{ translate("HomeYourInvoices") }</strong>
                                </CardHeader>
                                {
                                    config?.["records"]
                                    &&
                                        isEmpty(config["records"])
                                        ?
                                            <CardBody>
                                                <div style={{ margin: "50px 0", display: "flex", justifyContent: "center" }}>{ translate("HomeNothingHere") }</div>
                                            </CardBody>
                                        :
                                            <CardBody>
                                                <Table records={config["records"]} handleSelect={onRowSelection} resetAmount={resetAmount} isPopup={false} />
                                            </CardBody>
                                }
                            </Card>
                        </Row>
                    </div>
                :
                    <Loading />
            }
        </>
    );
}

// class Accueil extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       records: null,
//       showModal: false,
//       factAmountSelected: 0,
//       userInfos: null,
//       factSelected: []
//     }
//   }
  
//   componentDidUpdate(prevProps) {
// 	  if( this.props.match && (this.props.match.params.accueilParam1 === 'afterpay') ) {
// 						this.props.history.push("/accueil") ; //HACK
// 						//TODO : remove query ? 
// 						this.setState({showModal:true, showModalAfterpay:this.props.match.params.accueilParam2}) ;
// 	  }
//   }

//   handleModal = (torf) => {
// 	 var newState ;
// 	 if( torf != null ) {
// 		newState = torf ;
// 	 } else {
// 		 newState = !this.state.showModal ;
// 	 }
//     this.setState({
//       showModal: newState,
// 		showModalAfterpay: false,
//     })
//   }
//   handleModalOpen = () => {
// 		this.handleModal(true) ;
//   }
//   handleModelEnd = (payerStatus) => {
// 	  console.dir(payerStatus) ;
// 	  this.handleModal(false) ;
//   }
  

//   onRowSelection = (isSelected, row, isMultiple) => {
//     let amount = 0;
//     let indexes = [] ;
//     if (isMultiple === true){
//       row.forEach((rec) => {
//         let tmpAmount = rec.amount.toString().replace(/\s/g,'') ;
//         tmpAmount = tmpAmount.replace(",",'.') ;
//         tmpAmount = parseFloat(tmpAmount) ;
//         amount += tmpAmount ;
//       })
//     } else{
//       amount = row.amount.toString().replace(/\s/g,'') ;
//       amount = amount.replace(",",'.') ;
//       amount = parseFloat(amount) ;
//     }
//     if (amount <= 0) return ;
//     if (isSelected === true){
//       let res = this.state.factAmountSelected + amount ;
//       res = parseFloat(res.toFixed(2)) ;
//       let newFactSelect = [...this.state.factSelected] ;
//       if (isMultiple === true){
//         row.forEach((rec) => {
//           newFactSelect.push(rec) ;
//         }) ;
//       } else{
//         newFactSelect.push(row) ;
//       }
//       this.setState({
//         factAmountSelected: res,
//         factSelected: newFactSelect
//       })
//     } else{
//       if (amount < 0) amount = 0 ;
//       let res = this.state.factAmountSelected - amount ;
//       res = parseFloat(res.toFixed(2)) ;
//       let newFactSelect = [...this.state.factSelected] ;
//       let index ;
//       newFactSelect.forEach((fact, i) => {
//         if (isMultiple === true){
//           row.forEach((rec) => {
//             if (fact["record_ref"] === rec["record_ref"]){
//               indexes.push(i) ;
//             }
//           })

//         } else{
//           if (fact["record_ref"] === row["record_ref"]){
//             index = i ;
//           }
//         }

//       })
//       if (isMultiple !== true){
//         newFactSelect.splice(index, 1) ;
//       } else{
//         while(indexes.length) {
//           newFactSelect.splice(indexes.pop(), 1);
//         }
//       }
//       this.setState({
//         factAmountSelected: res,
//         factSelected: newFactSelect
//       })
//     }

//   }
//   resetAmount = () => {
//     this.setState({
//       factAmountSelected: 0
//     })
//   }
//   componentDidMount() {
//     createGetRequest("get_account")
//         .then(response => {
//           if (response.data.success === true){
//             let usrInfos = setUserInfos(response.data.data) ;
//             let data = setUserRecords(response.data.data) ;
//             this.setState({
//               records: data.records,
//               userInfos: usrInfos
//             }) ;
//           }
//         })
//         .catch(function (error) {
//           console.log(error) ;
//         })
//   }

  
//   render() {
//     if (this.state.userInfos === null || this.state.records === null) return loading() ;
//     return (
//       <div className="animated fadeIn">
//         <Row>
//           <Col className="col-accueil" xs="3">
//             <Card className="text-white bg-info" style={{height: '80%'}}>
//               <CardBody>
//                 <div style={{fontSize: '1.5rem', fontWeight: '600'}}>Montant dû (€)</div>

                
//                 <div style={{fontSize: '1.5rem', fontWeight: '600', textAlign: 'center', height: '80%',
//                   display: 'flex'}}>
//                   <div style={{margin: 'auto', fontSize: '2.5rem'}}>
//                     {new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(this.state.userInfos["amountDue"])}
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           </Col>
//           <Col className="col-accueil" xs="6">
//             <Card className="text-white bg-success" style={{height: '80%'}}>
//               <CardBody>
//                 <table style={{width: '100%'}} className="accueil-table">
//                   <tbody>
//                     <tr>
//                       <td className="accueil-td">Votre créancier</td>
//                       <td className="accueil-td-text">{this.state.userInfos["soc_txt"]}</td>
//                     </tr>
//                     <tr>
//                       <td className="accueil-td">Votre ref. client</td>
//                       <td className="accueil-td-text">{this.state.userInfos["acc_id"]}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </CardBody>
//             </Card>
//           </Col>
//           <Col className="col-accueil" xs="3" >
//             <Card className="text-white bg-transparent" style={{height: '80%', border: 'none'}}>
//               <div style={{height: '100%', display: 'flex', textAlign: 'center'}}>
//                 <div style={{margin: 'auto'}}>
//                   <div style={{width: '100%'}}>
//                     <Button color="primary" size="lg" onClick={this.handleModalOpen}>
//                       Procéder au paiement <br/>
//                       {this.state.factAmountSelected > 0 ? "(" + new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(this.state.factAmountSelected) + " €)"
//                         : null}
//                     </Button>
//                   </div>
//                   <div style={{width: '100%'}} id="nouveau-message-accueil">
//                     <Button color="primary" size="lg" onClick={() => {
//                         console.log(this.state.factSelected)
//                       if (this.state.factSelected.length === 0){
//                         // this.props.history.push("/nouveauMessage");
//                         useNavigate("/nouveauMessage");
//                       } else{
//                         this.props.history.push({pathname: '/nouveauMessage', state: {factSelected: this.state.factSelected}}) ;
//                       }
//                     }}>Nouveau message</Button>
//                   </div>
//                 </div>
//               </div>
//             </Card>
//           </Col>
//           <Payer isOpen={this.state.showModal} onModalEnd={this.handleModelEnd}
//                  afterPay={!!this.state.showModalAfterpay} afterPayCode={this.state.showModalAfterpay}
//                  accId={this.state.userInfos["acc_id"]}
//                  payAmount={this.state.factAmountSelected > 0 ?
//                    this.state.factAmountSelected : this.state.userInfos["amountDue"]}/>
//         </Row>
//         <Row>
//           <Card>
//             <CardHeader>
//               <strong>Vos factures</strong>
//             </CardHeader>
//             <CardBody>
//               <Table records={this.state.records} onSelect={this.onRowSelection} resetAmount={this.resetAmount} isPopup={false}/>
//             </CardBody>
//           </Card>
//         </Row>
//       </div>

//     );
//   }
// }
export default Accueil;