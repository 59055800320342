import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Alert } from "reactstrap";

import { useTranslation } from "contexts";
import { createGetRequest } from 'Utils/Common';

import Messagerie from "./Messagerie";

import './MessageView.css'

const MessageView = ({ ...props }) => {
    const [ messages, setMessages ] = useState(null);

    const navigate = useNavigate();
    const translate = useTranslation();

    useEffect(() => {
        createGetRequest("tchat_get_messages")
            .then(resp => resp.data)
            .then(resp => {
                if( resp.success === true ){
                    setMessages(resp.data)
                } else {
                    alert(translate("HomeModalPaidErrorHasOccurred") + " " + translate("MiscRefreshPage")) ;
                }
            })
            .catch(err => console.log(err))
    }, [])

    if( messages === null ) return null ;
    return (
        <>
            <Card>
                <CardHeader>
                <div>
                    <div className="userInfosTitleHeader">
                        <strong>{ translate("MailboxYourMailbox") }</strong>
                    </div>
                    <Button 
                        className="userInfosAddInfos" 
                        style={{ backgroundColor: '#20a8d8', borderColor: '#20a8d8' }}
                        onClick={() => navigate("/nouveauMessage") } 
                    >
                        { translate("MiscNewMessage") }
                    </Button>
                </div>
                </CardHeader>
                <CardBody>
                    <div className="Chat">
                        { 
                            messages.length === 0 
                            ?
                                <Alert color="warning">{ translate("MailboxNoMessage") }</Alert> 
                            :
                                <Messagerie messages={messages} />
                        }
                    </div>
                </CardBody>
            </Card>
            <div style={{ height: '20px' }}></div>
        </>
    );
}

export default MessageView;