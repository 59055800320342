import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { AppProvider, AuthProvider, TranslateProvider } from './contexts';

import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <AuthProvider>
        <TranslateProvider>
        <AppProvider>
            <App />
        </AppProvider>
        </TranslateProvider>
        </AuthProvider>
    </StrictMode>
);