import React from 'react';
import {
    Navigate,
    Outlet,
    useLocation
} from 'react-router-dom';

import { useAuth } from "contexts";

// handle the public routes
// function PublicRoute({ loggedIn, setLogin, location, component: Component, ...rest }) {
//   const searchParams = new URLSearchParams(location.search) ;
//   let logAgain = false ;
//   if (searchParams.get("usr") !== null && searchParams.get("pwd") !== null && searchParams.get("base") !== null){
//     logAgain = true ;
//   }
//   return (
//     <Route
//       {...rest}
//       render={(props) => loggedIn === false || logAgain === true ? <Component {...props} setLogin={setLogin} /> : <Navigate replace to={{ pathname: '/accueil' }} />}
//     />
//   )
// }

// function PublicRoute({ loggedIn, setLogin, location, component: Component, ...rest }) {
//     const searchParams = new URLSearchParams(location.search) ;
//     let logAgain = false ;
//     if (searchParams.get("usr") !== null && searchParams.get("pwd") !== null && searchParams.get("base") !== null){
//         logAgain = true ;
//     }

//     return loggedIn === false || logAgain === true ?  <Outlet {...rest} setLogin={setLogin} /> : <Navigate replace to={{ pathname: '/accueil' }} /> ;
// }

export const PublicRoutes = ({ redirectPath = "/accueil" }) => {
    const location = useLocation();
    const { auth } = useAuth();

    // console.log(auth)

    if( auth.isAuth && location?.state?.from?.pathname && location.state.from.pathname !== redirectPath ) {
        redirectPath = location.state.from.pathname;
    }

    return auth.isAuth ? <Navigate to={redirectPath} state={{ from: location }} replace /> : <Outlet /> ;
}

export const ProtectedRoutes = ({ redirectPath = "/login" }) => {
    const location = useLocation();
    const { auth } = useAuth();

    // console.log(auth)

    return auth.isAuth ? <Outlet/> : <Navigate to={redirectPath} state={{ from: location }} replace /> ;
};