import { ReactComponent as EnglishFlag } from "assets/flags/english-flag.svg";
import { ReactComponent as FrenchFlag } from "assets/flags/french-flag.svg";
import { ReactComponent as GermanFlag } from "assets/flags/german-flag.svg";
import { ReactComponent as ItalianFlag } from "assets/flags/italian-flag.svg";
import { ReactComponent as NetherlandsFlag } from "assets/flags/netherlands-flag.svg";
import { ReactComponent as PortugalFlag } from "assets/flags/portugal-flag.svg";
import { ReactComponent as SpanishFlag } from "assets/flags/spanish-flag.svg";

const svgProperties = { width:20, height:20, style:{ verticalAlign: 'baseline' } };

export const TRANSLATES = [
    { key: 'en', value: 'en', icon: <EnglishFlag {...svgProperties}/> },
    { key: 'fr', value: 'fr', icon: <FrenchFlag {...svgProperties}/> },
    { key: 'de', value: 'de', icon: <GermanFlag {...svgProperties}/> },
    { key: 'it', value: 'it', icon: <ItalianFlag {...svgProperties}/> },
    { key: 'nl', value: 'nl', icon: <NetherlandsFlag {...svgProperties}/> },
    { key: 'pt', value: 'pt', icon: <PortugalFlag {...svgProperties}/> },
    { key: 'es', value: 'es', icon: <SpanishFlag {...svgProperties}/> }
];