import { createContext, useState, useEffect, useContext } from "react";

import { createGetRequest } from "Utils/Common";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState({
        isAuth: null,
        isDev: null,
    });
    const [ appIsReady, setAppIsReady ] = useState(false);

    useEffect(() => {
        createGetRequest('get_isDev')
            .then(resp => resp.data)
            .then(resp => setAuth({ ...auth, isDev: resp?.data?.dev === false ? false : true }))
            .catch(err => console.log(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( auth.isAuth === null ) {
            createGetRequest('get_isLogged')
                .then(resp => resp.data)
                .then(resp => {
                    setAuth({ ...auth, isAuth: resp.success })
                    setTimeout(() => {
                        setAppIsReady(true);
                    }, 200)
                })
                .catch(err => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuth])

    return <AuthContext.Provider value={{ auth, appIsReady, setAuth }}>{ children }</AuthContext.Provider>;
}