import {Component} from "react";
import React from "react";
import { MessageList } from './Component/MessageList/MessageList'

class Messagerie extends Component {

  render() {
    const {messages} = this.props;
    return (
      <div className="animated fadeIn">
        <MessageList
          className='message-list'
          lockable={true}
          toBottomHeight={'100%'}
          dataSource={messages}
          dataLength={messages.length}/>
      </div>

    )
  }
}


export default Messagerie;
