import React from 'react';

import Login from "Login";

import Accueil from "./views/Recouveo/Accueil/Accueil";
import AccueilAfterPay from "./views/Recouveo/Accueil/AccueilAfterPay";
import NouveauMessage from "./views/Recouveo/NouveauMessage/NouveauMessage";
import Messagerie from "./views/Recouveo/Messagerie/MessageView";
import Informations from "./views/Recouveo/Informations/Informations.js";

export const publicRoutes = [
    { path: '/login', name: 'Login', element: <Login /> }
]

export const protectedRoutes = [
    { path: '/informations', name: 'UserInfosBtoC', element: <Informations /> },
    { path: '/messagerie', name: 'Messagerie', element: <Messagerie /> },
    { path: '/nouveauMessage', name: 'NouveauMessage', element: <NouveauMessage /> },
    { path: '/accueil/:accueilParam1?/:accueilParam2?', name: 'Accueil', element: <Accueil /> },
    { path: '/afterpay', name: 'AfterPay', element: <AccueilAfterPay /> }
]