import React, { useMemo, useCallback } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {numberFilter, textFilter} from 'react-bootstrap-table2-filter';

import { useTranslate, useTranslation } from "contexts";


const AccueilTable = ({ ...props }) => {
    const { resetAmount, records, handleSelect, isPopup } = props;

    // const [ fetchRecords, setFetchRecords ] = useState(records.slice());
    // const [ newRecords, setNewRecords ] = useState([]);
    // const [ showModal, setShowModal ] = useState(false);

    const { translations, lang } = useTranslate();
    const translate = useTranslation();
  
    const defaultSorted = useMemo(() => [{
        dataField: 'date_record',
        order: 'asc'
    }], []);

    const paginationOptions = useMemo(() => ({
		// pageStartIndex: 0,
		sizePerPage: 10,
		hideSizePerPage: false,
		hidePageListOnlyOnePage: true,
		onSizePerPageChange: (sizePerPage, page) => {
			resetAmount();
		},
		sizePerPageList: [{
			text: '5', value: 5
		}, {
			text: '10', value: 10
		}, {
			text: '25', value: 25
		}, {
			text: '50', value: 50
		}, {
			text: 'Tout', value: records.length
		}]
	}), [records, resetAmount]);

    const selectRow = useMemo(() => ({
		mode: 'checkbox',
		clickToSelect: true,
		onSelect: ( row, isSelect ) => {
			if( isPopup === true ) return ;
			handleSelect(isSelect, row);
		},
		onSelectAll: ( isSelect, rows, e ) => {
			if( isPopup === true ) return ;
			handleSelect( isSelect, rows, true );
		},
		hideSelectColumn: isPopup === true,
	}), [isPopup, handleSelect]);

    const textFormatter = useCallback((column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
            <div style={{ marginTop: "auto", marginBottom: "5px" }}>
                { column.text }
                { sortElement }
            </div>
            { filterElement }
        </div>
    ), []);
    
    const noFilterFormatter = useCallback((column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '200px' }}>
            <div style={{ marginTop: "auto", marginBottom: "5px" }}>
                { column.text }
            </div>
            {filterElement}
        </div>
    ), []);

    const commonFormatter = useCallback((column, colIndex, { sortElement, filterElement }) => (
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100px' }}>
            <div style={{ marginTop: "auto", marginBottom: "5px" }}>
                { column.text }
                { sortElement }
            </div>
            {filterElement}
        </div>
    ), []);

    const customFilterNumber = useCallback(( filterVal, data ) => {
        if( filterVal ) {
            switch( filterVal.comparator ) {
                case "=" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) === parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                case "!=" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) !== parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                case ">" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) > parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                case ">=" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) >= parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                case "<" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) < parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                case "<=" : return data.filter(row => parseFloat(row.amount.replace(/\s/g, '').replace(",", ".")) !== parseFloat(filterVal.number.replace(/\s/g, '').replace(",", ".")));
                default: break ;
            }
        }
        return data;
    }, []);

    const columns = useMemo(() => [
        {
			dataField: 'date_record',
			text: ( translate("HomeDate") ),
			style: {
				minWidth: "100",
				textAlign: 'center'
			},
			filter: textFilter({
				placeholder: ( translate("HomeDate") ),
			}),
			headerFormatter: commonFormatter,
			formatter: (cell,row,rowIndex,formatExtraData) => {
				let dateObj = new Date(cell) ;
				return dateObj.toLocaleDateString(lang) ;
			},
			sort: true,
			// here, we implement a custom sort which perform a reverse sorting
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				let calc ;
				let dateA = new Date(a),
					dateB = new Date(b) ;
				calc = ( dateB - dateA ) * (order === 'asc' ? 1 : -1 ) ;
				if( calc !== 0 ) {
					return calc ;
				}
				
				let idA = rowA['record_filerecord_id'],
					idB = rowB['record_filerecord_id'] ;
				calc = ( idB - idA ) * (order === 'asc' ? 1 : -1 ) ;
				if( calc !== 0 ) {
					return calc ;
				}
				return 0 ; // ?
			}
		},
		{
			dataField: 'record_ref',
			text: ( translate("HomeReference") ),
			headerFormatter: textFormatter,
            formatter: cell => {
                return <span style={{ wordBreak: "break-word" }}>{ cell }</span>
            },
			filter: textFilter({
				placeholder: ( translate("HomeReference") ),
			}),
			sort: true
		}, {
			dataField: 'record_txt',
			text: ( translate("HomeWording") ),
			headerFormatter: textFormatter,
            formatter: cell => {
                return <span style={{ wordBreak: "break-word" }}>{ cell }</span>
            },
			filter: textFilter({
				placeholder: ( translate("HomeWording") ),
			}),
			sort: true
		},
		{
			dataField: 'amount',
			text: ( translate("HomeAmount") + " (€)" ),
			filter: numberFilter({
				placeholder: ( translate("HomeAmount") ),
				onFilter: customFilterNumber
			}),
			style: {
				minWidth: "100px",
				textAlign: "right"
			},
			headerFormatter: commonFormatter,
			sort: true,
			formatter: (cell) => {
				return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(cell) ;
			}
		},
		{
			dataField: 'comment',
			text: ( translate("HomeObservation") ),
			headerFormatter: noFilterFormatter,
            formatter: cell => {
                return <span style={{ wordBreak: "break-word" }}>{ cell }</span>
            },
			style: {
				minWidth: "200px"
			},
			headerClasses: 'obs-header-column',
		}
    ].map(el => {
        // HACK : DM pas de filters pour le momment
        delete el.filter;
        return el;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [translations, lang]);

    // const handleModal = useCallback(() => {
    //     setShowModal(!showModal);
	// }, []);

    return (
        <div>
            <BootstrapTable
                bootstrap4
                hover
                striped
                keyField="record_filerecord_id"
                data={records}
                columns={columns}
                classes="table-responsive"
                pagination={paginationFactory(paginationOptions)}
                filter={filterFactory()}
                selectRow={selectRow}
                defaultSorted={defaultSorted}
                rowClasses="accueil-table-row"
            />
        </div>
    );
}

export default AccueilTable;