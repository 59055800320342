import React, { useMemo } from "react";

import { 
    Col,
    FormFeedback,
    FormGroup,
    Label,
    Input, 
} from 'reactstrap';

import { isNonEmpty } from "Utils/Common";

export const InputWithFeedbackV2 = ({ 
    id, 
    name, 
    placeholder, 
    required,
    type, 
    row = true,
    options = [], 
    values,
    touched = {}, 
    errors = {}, 
    onBlur,
    onChange,
    children 
}) => {
    const invalid = useMemo(() => (
        !!(touched?.[name] 
        && touched[name] === true 
        && errors?.[name])
    ), [touched, errors, name]);
    const props = useMemo(() => ({ 
        id, 
        name, 
        value: values[name], 
        placeholder, 
        required, 
        type, 
        onBlur, 
        invalid 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [values, invalid]);

    return (
        <FormGroup row={row}>
            <Col xs={6}>
                <Label for={id}>{ children }</Label>
                { required && <span style={{ color: 'red' }}>*</span> }
            </Col>
            <Col xs={6}>
                {
                    type
                    &&
                        type !== 'select'
                        ?
                            <Input { ...props } onChange={({ currentTarget }) => onChange(currentTarget.name, currentTarget.value)} />
                        :
                            type === 'select' && isNonEmpty(options)
                            &&
                                <Input { ...props } onChange={({ currentTarget }) => onChange(currentTarget.name, currentTarget.value)}>
                                    {
                                        options.map(opt => {
                                            const { code, displayName } = opt;
                                            return (
                                                <option
                                                    key={code}
                                                    value={code}
                                                >
                                                    { displayName }
                                                </option>
                                            );
                                        })
                                    }
                                </Input>
                }
                {
                    invalid === true &&
                        <FormFeedback>
                            { errors[name] }
                        </FormFeedback>
                }
            </Col>
        </FormGroup>
    );
}