import React from "react";

import { Input, FormFeedback } from 'reactstrap';

export const InputWithFeedback = ({ field, form: { touched, errors }, ...props}) => {
    return (
        <div>
			<Input
				invalid={!!(touched[field.name] && errors[field.name])}
				{ ...field }
				{ ...props }
            />
			{ 
                touched[field.name] && errors[field.name] 
                && <FormFeedback>{ errors[field.name] }</FormFeedback> 
            }
		</div>
    );
}