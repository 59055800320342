import React, { useEffect, useState, useMemo, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { Field, Form, Formik } from "formik";
import { 
    Button, 
    Card, 
    CardBody, 
    CardHeader, 
    Col, 
    FormGroup, 
    Label, 
    Row 
} from 'reactstrap';
import moment from 'moment';

import { InputWithFeedback } from "components";
import { useTranslation, useApp } from "contexts";
import { isNonEmpty } from "Utils/Common";

const Informations = ({ ...props }) => {
    const { config, config2 } = useApp();
    const navigate = useNavigate();
    const translate = useTranslation();

    const propart = useMemo(() => config["propart"] === "Particulier" ? "part" : "pro", [config]) ;

    const [ values, setValues ] = useState({});
    const [ valuesAreFilled, setValuesAreFilled ] = useState(false);

    useEffect(() => {
        if( isNonEmpty(config) && isNonEmpty(config2) && !valuesAreFilled ){
            const initValues = handleFillInitialValues();
            setValues(initValues);
            setValuesAreFilled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, config2, valuesAreFilled])

    const handleFillInitialValues = useCallback(( ) => {
        let initialValues = {
            propart: config["propart"],
            socName: config["accName"],
            siren: config["siren"],
            tel: config["tel"].replace(/\D/g,''),
            email: config["email"],
            rue: config["postal"]["addr1"],
            compl: config["postal"]["addr2"],
            postal: config["postal"]["cp"],
            city: config["postal"]["ville"],
            country: config["postal"]["country"],
            comment: ""
        };

        // Ajout des champs additionnels
        ['pro', 'part', 'both'].forEach(el => {
            if( config2.hasOwnProperty(el) ){
                config2[el].forEach(e => {
                    const { code } = e;
                    if( !initialValues.hasOwnProperty(code) ) {
                        initialValues[code] = "";
                    }
                })
            }
        })

        // Ajout des valeurs extData ou remplacement si !=
        if( config['extData'] && Array.isArray(config['extData']) && isNonEmpty(config['extData']) ){
            config['extData'].forEach(el => {
                const { code, valeur } = el;
                if( code === 'birthDate' && valeur ){
                    initialValues[code] = moment(valeur, "DD-MM-YYYY").format("YYYY-MM-DD");
                    return ;
                }
                if( initialValues.hasOwnProperty(code) && initialValues[code] !== valeur && code !== 'propart' ){
                    initialValues[code] = valeur;
                }
            })
        }

        return initialValues;
    }, [config, config2])

    return (
        <>
            {
                isNonEmpty(values) && isNonEmpty(config) && isNonEmpty(config2) && propart
                &&
                    <div>
                        <Formik
                            initialValues={values}
                            validationSchema=""
                            onSubmit={values => {}}
                            onReset={values => props.onReset() }
                        >
                            {() => (
                                <Form>
                                    <Card>
                                        <CardHeader>
                                            <Button color="primary" onClick={() => {
                                                navigate("/nouveauMessage", { state: { from: "informations" } });
                                            }}>
                                            { translate("InfoEditInformations") }
                                            </Button>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12">
                                                    <FormGroup row>
                                                        <Col xs="6">
                                                            <Label>{ translate("NewMessageIAmA") }</Label>
                                                            <Field component={InputWithFeedback} disabled type="text" name="propart"/>
                                                        </Col>
                                                    </FormGroup>
                                                    <div>
                                                        <FormGroup row>
                                                            {
                                                                Object.values(config2[propart]).map((arg) => (
                                                                    <Col xs="6" key={arg.code} style={{ marginBottom: "1rem" }}>
                                                                        <Label>{arg.labelName}</Label>
                                                                        <Field 
                                                                            disabled 
                                                                            required={arg.mandatory === true ? "required" : ""} 
                                                                            component={InputWithFeedback}
                                                                            type={arg.type !== undefined ? arg.type : "text"} 
                                                                            name={arg.code}
                                                                        />
                                                                    </Col>
                                                                ))
                                                            }
                                                        </FormGroup>
                                                    </div>
                                                    <div>
                                                        <FormGroup row>
                                                            {
                                                                Object.values(config2["both"]).map((arg) => (
                                                                    <Col xs="6" key={arg.code} style={{marginBottom: "1rem"}}>
                                                                        <Label>{arg.labelName}</Label>
                                                                        <Field 
                                                                            disabled 
                                                                            required={arg.mandatory === true ? "required" : ""} 
                                                                            component={InputWithFeedback}
                                                                            type={arg.type !== undefined ? arg.type : "text"} 
                                                                            name={arg.code}
                                                                        />
                                                                    </Col>
                                                                ))
                                                            }
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </>
    );
}

export default Informations;