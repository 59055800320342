import React, { Component } from 'react';
import './MessageFile.css';

// import FaCloudDownload from 'react-icons/lib/fa/cloud-download';
import { FaCloudDownloadAlt } from "react-icons/fa";

// import FaError from 'react-icons/lib/fa/exclamation-triangle';
import { FaExclamationTriangle } from "react-icons/fa";

// import FaFilePdf from 'react-icons/lib/fa/file-pdf-o';
import { FaFilePdf } from "react-icons/fa6";

// import FaFile from 'react-icons/lib/fa/file';
import { FaFile } from "react-icons/fa";

// import FaFileExcel from 'react-icons/lib/fa/file-excel-o';
import { FaFileExcel } from "react-icons/fa6";

// import FaFileTxt from 'react-icons/lib/fa/file-text-o' ;
import { FaFileAlt } from "react-icons/fa";

const ProgressBar = require('react-progressbar.js');
const Circle = ProgressBar.Circle;

export class MessageFile extends Component {

    onClick(e) {
        // if (!this.props.data.status)
        //     return;
        //
        // if (!this.props.data.status.download && this.props.onDownload instanceof Function)
        //     this.props.onDownload(e);
        // else if (this.props.data.status.download && this.props.onOpen instanceof Function)
        //     this.props.onOpen(e);
        // console.log(this.props) ;
        this.props.onOpen(this.props.mediaId);
    }

    renderIcon(e) {
        let extension = this.props.text.split('.').pop();
        switch (extension) {
            case "pdf":
                return <FaFilePdf color='#2f353a'/>
            case "xls":
            case "xlsx":
                return <FaFileExcel color='#2f353a'/>
            case "txt":
                return <FaFileAlt color='#2f353a'/>
            default:
                return <FaFile color='#2f353a'/>
        }
    }
    render() {
        let progressOptions = {
            strokeWidth: 5,
            color: '#333',
            trailColor: '#aaa',
            trailWidth: 5,
            step: (state, circle) => {
                circle.path.setAttribute('trail', state.color);
                circle.path.setAttribute('trailwidth-width', state.width);

                var value = Math.round(circle.value() * 100);
                if (value === 0)
                    circle.setText('');
                else
                    circle.setText(value);
            }
        };

        const error = this.props.data.status && this.props.data.status.error === true;
        return (
            <div className='rce-mbox-file'>
                <button onClick={this.onClick.bind(this)}>
                    <div className="rce-mbox-file--icon">
                        {this.renderIcon()}
                        <div className="rce-mbox-file--size">
                            {this.props.data.size}
                        </div>
                    </div>
                    <div className="rce-mbox-file--text">
                        {this.props.text}
                    </div>
                    <div className="rce-mbox-file--buttons">
                        {
                            error &&
                            <span className="rce-error-button">
                                <FaExclamationTriangle
                                    color='#ff3d3d'/>
                            </span>
                        }
                        {
                            !error &&
                            this.props.data.status &&
                            !this.props.data.status.download &&
                            !this.props.data.status.click &&
                            <FaCloudDownloadAlt
                                color='#aaa'/>
                        }
                        {
                            !error &&
                            this.props.data.status &&
                            typeof this.props.data.status.loading === 'number' &&
                            this.props.data.status.loading !== 0 &&
                            <Circle
                                progress={this.props.data.status.loading}
                                options={progressOptions}
                                initialAnimate={true}
                                containerClassName={'rce-mbox-file--loading'} />
                        }
                    </div>
                </button>
            </div>
        );
    }
}

MessageFile.defaultProps = {
    text: '',
    data: {},
    onClick: null,
    onDownload: null,
    onOpen: null,
};


export default MessageFile;
