import React, { useEffect, useState, useCallback } from "react";

import { Button, Col, Form, Row } from "reactstrap";

import { InputWithFeedbackV2 as Input } from "components";
import { useTranslate, useTranslation, useApp } from "contexts";
import { createPostRequest, isEmpty, isNonEmpty } from "Utils/Common";

// import UploadComponent from "./UploadComponent";
import UploadComponent2 from "./UploadComponent2";

import AddCommentModal from "views/Recouveo/NouveauMessage/Modals/AddCommentModal";
import ConfirmModal from "views/Recouveo/NouveauMessage/Modals/ConfirmModal";
import ErrorModal from "views/Recouveo/Modals/ErrorModal";

const ModifyInfos3 = ({ ...props }) => {
    const { 
        factSelected, 
        uploadFiles, 
        addResult, 
        addFiles, 
        formSent
    } = props;

    const [ touched, setTouched ] = useState({});
    const [ errors, setErrors ] = useState({});

    const [ files, setFiles ] = useState([]);

	const [ showCommentModal, setShowCommentModal ] = useState(false);
	const [ showErrorModal, setShowErrorModal ] = useState(false);
	const [ showConfirmModal, setShowConfirmModal ] = useState(false);

	const [ errTxt, setErrTxt ] = useState(null);

	const [ currentFile, setCurrentFile ] = useState(null);

    const { lang } = useTranslate();
    const translate = useTranslation();
    const { config, config2 } = useApp();

    const [ values, setValues ] = useState({});
    const [ valuesAreFilled, setValuesAreFilled ] = useState(false);

    useEffect(() => {
        if( isNonEmpty(config) && isNonEmpty(config2) && !valuesAreFilled ){
            const initValues = handleFillInitialValues();
            setValues(initValues);
            setValuesAreFilled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, config2, valuesAreFilled])

    const handleFillInitialValues = useCallback(( ) => {
        let initialValues = {
            propart: config["propart"] === "Particulier" ? "part" : "pro",
            socName: config["accName"],
            siren: config["siren"],
            tel: config["tel"].replace(/\D/g,''),
            email: config["email"],
            rue: config["postal"]["addr1"],
            compl: config["postal"]["addr2"],
            postal: config["postal"]["cp"],
            city: config["postal"]["ville"],
            country: config["postal"]["country"],
            comment: ""
        };

        // Ajout des champs additionnels
        ['pro', 'part', 'both'].forEach(el => {
            if( config2.hasOwnProperty(el) ){
                config2[el].forEach(e => {
                    const { code } = e;
                    if( !initialValues.hasOwnProperty(code) ) {
                        initialValues[code] = "";
                    }
                })
            }
        })

        // Ajout des valeurs extData ou remplacement si !=
        if( config['extData'] && Array.isArray(config['extData']) && isNonEmpty(config['extData']) ){
            config['extData'].forEach(el => {
                const { code, valeur } = el;
                if( initialValues.hasOwnProperty(code) && initialValues[code] !== valeur && code !== 'propart' ){
                    if( code === 'propart' ){
                        initialValues[code] = (valeur === "Particulier" ? "part" : "pro");
                        return ;
                    } 
                    initialValues[code] = valeur;
                }
            })
        }

        return initialValues;
    }, [config, config2])

    const handleBlur = ({ currentTarget }) => {
        const { name } = currentTarget;
        setTouched({ ...touched, [name]: true });
        const required = isRequired(name);
        if( required && !values[name] ) setErrors({ ...errors, [name]: translate("MiscFieldRequired") });
    }

    const handleChange = useCallback(( name, value ) => {
        setValues({ ...values, [name]: value });
        const required = isRequired(name);
        if( required && errors[name] && value ){
            let errorsCopy = {...errors};
            delete(errorsCopy[name]);

            setErrors(errorsCopy);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, errors])

    const handleAddFiles = ( e ) => {
        if (e.target.files.length > 3 || files.length + e.target.files.length > 3) {
            setErrTxt( translate("NewMessageModalTooMuchFiles") );
            setShowErrorModal(true);
            return;
        }
        let issue = false;
        const filesUploaded = Object.values(e.target.files);
        filesUploaded.forEach((file, i) => {
            if (file.size >= 10000000) {
                setErrTxt( translate("NewMessageModalTooMuchSize") );
                setShowErrorModal(true);
                issue = true;
            }
        });
        if( issue === true ) return;
        addFilesToState(e);
        handleChange("files", files);
    }

    const handleSubmit = ( ) => {
        const valid = formIsValid();
        if( !valid ) return;

        let title = translate("NewMessageNewInformations");
        let text ;
        text = translate("NewMessageIAmA") + ": " + (values.propart === 'part' ? translate("NewMessageIndividual") : translate("NewMessageProfessional")) + "\r\n" ;

        Object.values(config2[values.propart]).forEach((arg) => {
            if (arg.code === "birthDate"){
                if (values.birthDate !== ""){
                    text += arg.displayName + ": " + new Date(values[arg.code]).toLocaleDateString(lang) + "\r\n" ;
                }
            } else{
                text += arg.displayName + ": " + values[arg.code] + "\r\n" ;
            }
        })

        Object.values(config2["both"]).forEach((arg) => {
            text += arg.displayName + ": " + values[arg.code] + "\r\n" ;
        })

        text += translate("NewMessageComment") + ": " + values.comment + "\r\n";

        if( isNonEmpty(factSelected) ){
            text += translate("NewMessageAffectedInvoices") + ": " ;
            factSelected.forEach((rec) => {
                text += rec["record_ref"] + ", " ;
            })
            text = text.slice(0, -2) ;
            text += "\r\n" ;
        }

        let error = false;

        if( isNonEmpty(files) ) {
            uploadFiles(files)
                .then(response => {
                    response.forEach((res, i) => {
                        if (res.data !== null && res.data.success !== true) {
                            error = true;
                        }
                    });
                    if (error === true) {
                        formSent("-1");
                        return;
                    }
                    text = text + translate("NewMessageAttachments") + ": \r\n";
                    files.forEach((file, i) => {
                        if (file.newName !== "") text = text + file.newName + "\r\n";
                        else text = text + file.name + "\r\n";

                    });
                    let msgAction = {
                        "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                        "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
                    };
                    createPostRequest("tchat_post_formulaire", {
                        "file_filerecord_id": config["fileOpenFilerecordId"],
                        "data": JSON.stringify(msgAction),
                        "values": JSON.stringify(values)
                    })
                        .then(response => {
                            if (response.data.success !== true) {
                                formSent("-1");
                            } else formSent("7");
                        })
                })
                .catch(err => {
                    error = true;
                    addResult(false);
                    formSent("-1");
                });
        } else {
            let msgAction = {
                "fileaction_filerecord_id": null, "link_status": "S1_OPEN",
                "link_action": "TCHAT_IN", "tchat_title": title, "tchat_txt": text
            };
            createPostRequest("tchat_post_formulaire", {
                "file_filerecord_id": config["fileOpenFilerecordId"],
                "data": JSON.stringify(msgAction),
                "values": JSON.stringify(values)
            })
                .then(response => {
                    if( response.data.success !== true ) {
                        formSent("-1");
                    } else formSent("7");
                })
        }
    }

    const formIsValid = ( ) => {
        let mandatoryArr = [];
        config2[values['propart']].forEach(el => {
            const { mandatory, code } = el;
            if( mandatory && !mandatoryArr.includes(code) ) mandatoryArr.push(code);
        })

        config2['both'].forEach(el => {
            const { mandatory, code } = el;
            if( mandatory && !mandatoryArr.includes(code) ) mandatoryArr.push(code);
        })

        let errorsArr = [];
        mandatoryArr.forEach(el => {
            if( values.hasOwnProperty(el) && !values[el] ){
                errorsArr.push(el);
            }
        })

        if( isEmpty(errorsArr) ) return true;
        let errorsCopy = {...errors};
        let touchedCopy = {...touched};

        errorsArr.forEach(el => {
            errorsCopy[el] = translate("MiscFieldRequired");
            touchedCopy[el] = true;
        })

        setErrors(errorsCopy);
        setTouched(touchedCopy);
        return false;
    }

    const addFilesToState = useCallback(( e )  => {
		setFiles(addFiles(e, files));
	}, [addFiles, files])

    // const setFileNewName = useCallback((name, i) => {
    //     let newFiles = [...files];

	// 	if (newFiles[i] === undefined || newFiles[i] === null) return;
	// 	newFiles[i].newName = name;

    //     setFiles(newFiles);
	// }, [files])

    const isRequired = useCallback(( name ) => {
        let isRequired = false;

        if( name === 'propart' ) return true;

        ['pro', 'part', 'both'].forEach(el => {
            if( config2.hasOwnProperty(el) ){
                config2[el].forEach(v => {
                    if( !isRequired && v['code'] === name ){
                        isRequired = v['mandatory'];
                    }
                })
            }
        })

        return isRequired;
    }, [config2])

    return (
        <>
            {
                isNonEmpty(values) && isNonEmpty(config2) &&
                    <>
                        <AddCommentModal 
                            isOpen={showCommentModal} 
                            content={currentFile !== null ? files[currentFile].comment : ""}
                            toggle={() => setShowCommentModal(!showCommentModal)}
                            onChange={(e) => {
                                if (e === null) {
                                    setShowCommentModal(false);
                                    return;
                                }
                                files[currentFile].comment = e.target.value;
                            }}
                        />
                        <ErrorModal isOpen={showErrorModal} error={errTxt} toggle={() => setShowErrorModal(!showErrorModal)}/>
                        <ConfirmModal 
                            isOpen={showConfirmModal} 
                            toggle={() => setShowConfirmModal(!showConfirmModal)}
                            validate={() => {
                                setShowConfirmModal(false);
                                handleSubmit();
                            }}
                        />
                        <Form>
                            <Row>
                                <Col xs={6}>
                                    <Input
                                        id="iAmA"
                                        name="propart"
                                        type="select"
                                        required={isRequired("propart")}
                                        options={config2["types"]}
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        { translate("NewMessageIAmA") }
                                    </Input>
                                    {
                                        Object.values(config2[values?.['propart']]).map((el, i) => {
                                            const { code, displayName, mandatory, type } = el;

                                            return (
                                                <Input
                                                    key={code}
                                                    id={"field-" + i}
                                                    name={code}
                                                    type={type ? type : "text"}
                                                    required={mandatory}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    { displayName }
                                                </Input>
                                            );
                                        })
                                    }
                                    {
                                        Object.values(config2['both']).map((el, i) => {
                                            const { code, displayName, mandatory, type } = el;

                                            return (
                                                <Input
                                                    key={code}
                                                    id={"field-both-" + i}
                                                    name={code}
                                                    type={type ? type : "text"}
                                                    required={mandatory}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                >
                                                    { displayName }
                                                </Input>
                                            );
                                        })
                                    }
                                </Col>
                                <Col xs={6}>
                                    <UploadComponent2 
                                        files={files}
                                        onChange={handleChange}
                                        onAddFiles={handleAddFiles}
                                        currentFile={currentFile}
                                        setCurrentFile={setCurrentFile}
                                        setShowCommentModal={setShowCommentModal} 
                                        setFiles={setFiles} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="mb-3 d-flex justify-content-center">
                                    <div style={{color: "red"}}>{ translate("MiscFieldsRequired") }</div>
                                </Col>
                                <Col xs={12} className="d-flex justify-content-center">
                                    <Button 
                                        color="primary" 
                                        onClick={() => setShowConfirmModal(true) }
                                    >
                                            { translate("MiscSendMessage") }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
            }
        </>
    );
}

export default ModifyInfos3;