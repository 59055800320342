import React, { useEffect, useState, useMemo } from "react";

import { NavLink, useLocation } from "react-router-dom";

import { 
    Col,
    Dropdown, 
    Menu, 
    Row, 
} from "antd";

import { useAuth, useTranslation, useTranslate, useApp } from "contexts";
import { createGetRequest, isNonEmpty } from "Utils/Common";
import { protectedRoutes } from "routes";

import logo from "../../assets/img/brand/logo.png";
import defaultAvatar from "../../assets/img/avatars/9.jpg";

export const Navbar = ({ ...props }) => {
    const [ current, setCurrent ] = useState('home');
    
    const location = useLocation();
    const { auth, setAuth } = useAuth();
    const { config } = useApp();
    const { translations } = useTranslate();
    const translate = useTranslation();

    const onClick = (e) => {
        setCurrent(e.key);
    };

    const onLogout = () => {
        createGetRequest('logout')
            .then(resp => resp.data)
            .then(resp => setAuth({ ...auth, isAuth: !(resp.success) }))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        setCurrent(location.pathname);
    }, [location?.pathname])

    const items = useMemo(() => [
        {
            label: (
            <NavLink to="/accueil" end className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
                { translate("NavHome") }
            </NavLink>
            ),
            key: '/accueil'
        },
        {
            label: (
                <NavLink to="/nouveauMessage" end className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
                    { translate("MiscNewMessage") }
                </NavLink>
            ),
            key: '/nouveauMessage'
        },
        {
            label: (
                <NavLink to="/messagerie" end className={({ isActive }) => "nav-link" + (isActive ? " activated" : "")}>
                    { translate("NavMailbox") }
                </NavLink>
            ),
            key: '/messagerie'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [translations]);

    const splitUrl = ( url ) => {
        const delimiter = '/', start = 2;
        const tmp = url.split(delimiter).slice(0, start);
        return tmp.join(delimiter);
    }
    
    const allowedUrls = protectedRoutes.map(el => splitUrl(el.path));

    const menu = [
        { 
            label: (
                <div onClick={() => onLogout()}>
                    <span className="me-2"><i className="fa fa-lock"/></span> 
                    <span>{ translate("NavLogout") }</span>
                </div>
            ),
            key: '1' 
        }
    ];

    return (
        <>
            {
                location?.pathname && allowedUrls.includes(location.pathname) && isNonEmpty(config)
                &&
                    <nav className="navbar" style={{ marginBottom: "20px" }}>
                        <div className="container-fluid">
                            <Row gutter={16} className="w-100">
                                <Col flex={1}>
                                    <NavLink to="/" className="navbar-brand">
                                        <img src={logo} alt="Recouveo Logo" style={{ height: 40, width: 80 }} />
                                    </NavLink>
                                </Col>
                                <Col flex={5}>
                                    <Menu 
                                        className="w-100 d-flex justify-content-center" 
                                        onClick={onClick} 
                                        selectedKeys={[current]} 
                                        mode="horizontal" 
                                        items={items}                             
                                    />
                                </Col>
                                <Col flex={1}>
                                    <Row className="w-100 justify-content-end align-items-end">
                                        <div className="d-flex flex-column align-items-center me-2">
                                            <div>
                                                { config['accId'] }
                                            </div>
                                            <div>
                                                <NavLink to="/informations" end style={{ textDecoration: "none", color: "#20a8d8" }}>
                                                    { config['accName'] }
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div>
                                            <Dropdown
                                                menu={{items: menu}}
                                                trigger={['click']}
                                            >
                                                <img src={defaultAvatar} alt="admin@bootstrapmaster.com" style={{ height: 30, width: 30, borderRadius: '50%', cursor: 'pointer' }} />
                                            </Dropdown>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </nav>
            }
        </>
    );
}