import React, { useState, useEffect, useMemo } from 'react';

import { Row } from 'reactstrap';

import { useTranslation, useTranslate } from "contexts";
// import { usePrevious } from 'hooks';
import { b64toBlob, createPostRequest } from "Utils/Common";

import MessageFile from "../MessageFile/MessageFile";

import './MessageBox.css';

const MessageBox = ({ ...props }) => {
    const { 
        data,
        // focus, 
        media_id, 
        tchat_title,
        tchat_txt,
        tchat_date,
        tchat_action,
        type, 
        // onMessageFocused, 
        onDownload
    } = props;

    const [ payload, setPayload ] = useState({
        position: "",
        positionCls: "",
        title: "",
        txt: "",
        tchat_text: ""
    });

    // const focusPrev = usePrevious(focus);
    const translate = useTranslation();
    const { lang, translations } = useTranslate();

    // useEffect(() => {
    //     if( focusPrev !== focus && focusPrev === true ) {
    //         if( this.refs['message'] ) {
    //             this.refs['message'].scrollIntoView({
    //                 block: "center",
    //                 behavior: 'smooth'
    //             }) ;
    //             onMessageFocused(focusPrev);
    //         }
    //       }
    // }, [focus, focusPrev])

    useEffect(() => {
        let position, positionCls, title, txt, tchat_text ;

        if( tchat_action === "TCHAT_IN" ){
            positionCls = 'mbox-right' ;
            position = "right" ;
            title = tchat_title ;
            txt = translate("MiscYou") + " - " + new Date(tchat_date).toLocaleDateString(lang, options) ;
        } else{
            positionCls = 'mbox-left' ;
            position = "left" ;
            title = translate("MiscAnswerToYourRequest") ;
            txt = "Recouveo - " + new Date(tchat_date).toLocaleDateString(lang, options) ;
        }
        if( type === "file" ){
            let arr = tchat_txt.split(":", 2) ;
            tchat_text = arr[1] ;
        } else {
            tchat_text = tchat_txt ;
        }

        setPayload({
            position,
            positionCls,
            title,
            txt,
            tchat_text
        })
    }, [tchat_action, tchat_title, tchat_date, tchat_txt, translations])

    const onOpen = (mediaId) => {
        createPostRequest("get_tchat_file", {accBin: media_id})
            .then(response => {
                let bin = b64toBlob(response.data.bin) ;
                const url = window.URL.createObjectURL(new Blob([bin]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error) ;
            })
    }

    const options = useMemo(() => ({ 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric' 
    }), []);

    return (
        <div>
            {
                type === "file" 
                ?
                    <div>
                        <Row className={"chatRow-"+payload.position}>
                            <div className={"mbox-title-" + payload.position} data-text={ payload.txt }/>
                        </Row>
                        <Row className={"chatRow-"+payload.position}>
                            <div className={payload.positionCls} style={{background: "none"}}>
                                <MessageFile
                                    onOpen={onOpen}
                                    onDownload={onDownload}
                                    data={data}
                                    text={payload.tchat_text}
                                    mediaId={media_id}/>
                            </div>
                        </Row>
                    </div>
                :
                    <div>
                        <Row className={"chatRow-"+payload.position}>
                            <div className={"mbox-title-" + payload.position} style={{ display: 'flex', justifyContent: payload.position }} data-text={ payload.txt }/>
                        </Row>
                        <Row className={"chatRow-"+payload.position}>
                            <div className={payload.positionCls}>
                                <div className="mbox-body">
                                    <div className="mbox-text">
                                        <strong>{ payload.title } </strong>
                                        <br/>
                                        <br/>
                                        { payload.tchat_text}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
            }
        </div>
    );
}

// export class MessageBox extends Component {

//   componentDidUpdate(prevProps, prevState){
//     if (prevProps.focus !== this.props.focus && prevProps.focus === true) {
//       if (this.refs['message']) {
//         this.refs['message'].scrollIntoView({
//           block: "center",
//           behavior: 'smooth'
//         }) ;
//         this.props.onMessageFocused(prevProps);
//       }
//     }
//   }

//   onOpen(mediaId) {
//     createPostRequest("get_tchat_file", {accBin: this.props.media_id})
//         .then(response => {
//           let bin = b64toBlob(response.data.bin) ;
//           const url = window.URL.createObjectURL(new Blob([bin]));
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute('download', response.data.filename); //or any other extension
//           document.body.appendChild(link);
//           link.click();
//         })
//         .catch(error => {
//           console.log(error) ;
//         })
//   }
//   render() {
//     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
//     let position, positionCls, title, txt ;
//     let tchat_txt ;
//     if (this.props.tchat_action === "TCHAT_IN"){
//       positionCls = 'mbox-right' ;
//       position = "right" ;
//       title = this.props.tchat_title ;
//       txt = "Vous - " + new Date(this.props.tchat_date).toLocaleDateString('fr-FR', options) ;
//     } else{
//       positionCls = 'mbox-left' ;
//       position = "left" ;
//       title = "Réponse à votre demande" ;
//       txt = "Recouveo - " + new Date(this.props.tchat_date).toLocaleDateString('fr-FR', options) ;
//     }
//     if (this.props.type === "file"){
//       let arr = this.props.tchat_txt.split(":", 2) ;
//       tchat_txt = arr[1] ;
//     } else {
//       tchat_txt = this.props.tchat_txt ;
//     }
//     return (
//         <div>
//             {
//                 this.props.type === "file" 
//                 ?
//                     <div>
//                         <Row className={"chatRow-"+position}>
//                             <div className={"mbox-title-" + position} data-text={txt}/>
//                         </Row>
//                         <Row className={"chatRow-"+position}>
//                             <div className={positionCls} style={{background: "none"}}>
//                                 <MessageFile
//                                     onOpen={() => this.onOpen()}
//                                     onDownload={this.props.onDownload}
//                                     data={this.props.data}
//                                     text={tchat_txt}
//                                     mediaId={this.props.media_id}/>
//                             </div>
//                         </Row>
//                     </div>
//                 :
//                     <div>
//                         <Row className={"chatRow-"+position}>
//                             <div className={"mbox-title-" + position} style={{ display: 'flex', justifyContent: position }} data-text={txt}/>
//                         </Row>
//                         <Row className={"chatRow-"+position}>
//                             <div className={positionCls}>
//                                 <div className="mbox-body">
//                                     <div className="mbox-text">
//                                         <strong> {title} </strong>
//                                         <br/>
//                                         <br/>
//                                         {tchat_txt}
//                                     </div>
//                                 </div>
//                             </div>
//                         </Row>
//                     </div>
//             }
//         </div>
//     );
//   }
// }

// MessageBox.defaultProps = {
//   position: 'left',
//   type: 'text',
//   text: '',
//   title: null,
//   titleColor: null,
//   onTitleClick: null,
//   onForwardClick: null,
//   date: new Date(),
//   data: {},
//   onClick: null,
//   onOpen: null,
//   onDownload: null,
//   onLoad: null,
//   onPhotoError: null,
//   forwarded: false,
//   status: null,
//   dateString: null,
//   notch: true,
//   avatar: null,
//   renderAddCmp: null,
//   copiableDate: false,
//   onContextMenu: null,
//   focus: false,
//   onMessageFocused: null,
// };

export default MessageBox;