import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap' ;

import { useTranslation } from "contexts";

import classNames from 'classnames/bind';

const PayerAlert = ({ ...props }) => {
    const { errorMessage, errorColor, onErrorButton } = props;

    const [ alterColor, setAlterColor ] = useState(null);

    const translate = useTranslation();

    const handleButton = (btnId) => {
		switch( btnId ) {
			case 'close' :
				onErrorButton(btnId) ;
				break;
			default :
				break ;
		}
	}

    var classes = classNames({
        payeralert: true
    });

    useEffect(() => {
        let altColor ;
        switch( errorColor ) {
            case 'red' :
                altColor = 'danger' ;
                break ;
            case 'green' :
                altColor = 'success' ;
                break ;
            default :
                altColor = 'primary' ;
                break ;
        }

        setAlterColor(altColor);
    }, [errorColor])

    return (
        <div className={classes}>
			<ModalHeader>
				<strong>{ translate("HomeModalPaidFormAboutMakePayment") }</strong>
			</ModalHeader>
			<ModalBody>
			<div>
				<Alert color={alterColor}>
                    <div className="payeralert-text">
                        {errorMessage}
                    </div>
				</Alert>
			</div>
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => handleButton("close")} color="danger" type="reset">{ translate("MiscClose") }</Button>
			</ModalFooter>
        </div>
    );
}

// class PayerAlert extends Component {
// 	handleButton(btnId) {
// 		//console.dir(arguments) ;
// 		switch( btnId ) {
// 			case 'close' :
// 				this.props.onErrorButton(btnId) ;
// 				break;
// 			default :
// 				break ;
// 		}
// 		//console.dir(e.target) ;
// 		//console.log(e.target.attributes.btnid.value) ;
// 		//this.props.onErrorButton({button:buttonId}) ;
// 	}
// 	render() {
// 		var classes = classNames({
// 			payeralert: true
// 		});
// 		var alterColor ;
// 		switch( this.props.errorColor ) {
// 			case 'red' :
// 				alterColor = 'danger' ;
// 				break ;
// 			case 'green' :
// 				alterColor = 'success' ;
// 				break ;
// 			default :
// 				alterColor = 'primary' ;
// 				break ;
// 		}
// 		return (
// 			<div className={classes}>
// 			<ModalHeader>
// 				<strong>Vous êtes sur le point de procéder au paiement</strong>
// 			</ModalHeader>
// 			<ModalBody>
// 			<div>
// 				<Alert color={alterColor}>
// 				<div className="payeralert-text">
// 				{this.props.errorMessage}
// 				</div>
// 				</Alert>
// 			</div>
// 			</ModalBody>
// 			<ModalFooter>
// 				<Button onClick={()=>{this.handleButton("close");}} color="danger" type="reset">Fermer</Button>
// 			</ModalFooter>
// 			</div>
// 		) ;
// 	}
	
// }

export default PayerAlert ;
