import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Spinner } from 'reactstrap';

import { useTranslation } from "contexts";
import { usePrevious } from "hooks";
import { createPostRequest } from 'Utils/Common' ;

import PayerForm from './PayerForm' ;
import PayerVendor from './PayerVendor' ;
import PayerAlert from './PayerAlert' ;

import './Payer.scss';

const Payer = ({ ...props }) => {
    const { isOpen, onModalEnd, afterPay, afterPayCode, accId, payAmount } = props;

    const [ loading, setLoading ] = useState(true);
    // const [ records, setRecords ] = useState(null);
    const [ transactionStep, setTransactionStep ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ errorColor, setErrorColor ] = useState(null);

    const [ formValues, setFormValues ] = useState({});

    const [ vendorFormConfig, setVendorFormConfig ] = useState({});

    const isOpenPrev = usePrevious(isOpen);
    const translate = useTranslation();

    useEffect(() => {
        if( !isOpen ) return ;
        if( isOpen !== isOpenPrev ){
            return afterPay ? afterTransaction(afterPayCode) : beginTransaction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isOpenPrev, afterPay, afterPayCode])

    const afterTransaction = (afterPayCode) => {
		setLoading(true);
		setTimeout(() => {
			afterTransactionDo(afterPayCode) ;
		}, 2000);
	}

	const afterTransactionDo = (afterPayCode) => {
		createPostRequest("pay_getAfterpayStatus")
			.then(response => {
				let ajaxData = response.data ;
				if (ajaxData.success === false) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
				let errMsg, errColor ;
				switch( afterPayCode ) {
					case 'OK' :
						switch( ajaxData.pay_status ) {
							case 'OK' :
								errMsg = translate("MiscYourPaymentOf") + " " + ajaxData.pay_amount + " " + ajaxData.pay_currency + " " + translate("MiscIsConfirmed").toLowerCase() + ".\n" + translate("MiscWeThankYou") + "." ;
								errColor = 'green' ;
								break ;
							default :
								errMsg = translate("HomeModalPaidPaymentAwaitingReceipt") + " " + translate("HomeModalPaidPleaseLogBack")
								errColor = 'green' ;
								break ;
						}
						break ;
					case 'KO' :
						switch( ajaxData.pay_status ) {
							case 'OK' :
								errMsg = translate("MiscYourPaymentOf") + " " + ajaxData.pay_amount + " " + ajaxData.pay_currency + " " + translate("MiscIsConfirmed").toLowerCase() + ".\n" + translate("MiscWeThankYou") + "." ;
								errColor = 'green' ;
								break ;
							case 'FAIL' :
								errMsg = translate("HomeModalPaidPaymentFailed") + " " +  translate("HomeModalPaidPleaseRepeatOperationOrContactUs") ;
								errColor = 'red' ;
								break ;
							case 'NONE' :
								errMsg =  translate("HomeModalPaidOperationCancelled")  ;
								errColor = 'red' ;
								break ;
							default :
								errMsg = translate("HomeModalPaidErrorHasOccurred") + " " + translate("HomeModalPaidPleaseContactUs") ;
								errColor = 'red' ;
								break ;
						}
						break ;
					default :
						errMsg = translate("HomeModalPaidErrorHasOccurred") + " " +  translate("HomeModalPaidPleaseContactUs") ;
						errColor = 'red' ;
						break ;
				}
                setLoading(false);
                setTransactionStep('E_alert');
                setErrorMessage(errMsg);
                setErrorColor(errColor);
			})
			.catch(function (error) {
				console.log(error) ;
			});
		
	}
	
	const beginTransaction = () => {
        setLoading(true);
		createPostRequest("pay_pre_getAdr")
			.then(response => {
				let ajaxData = response.data ;
				if (ajaxData.success !== true) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
				let fValues = {
					pay_amount: payAmount
				}
				Object.assign(fValues,ajaxData.data) ;
                setLoading(false);
                setTransactionStep('1_form');
                setFormValues(fValues);
			})
			.catch(function (error) {
				console.log(error) ;
			});
		/*
		let timerid = setTimeout(() => {
		}, 2000);
		*/
	}
	
	const handleFormAction = (form,actionId,values) => {
		switch( actionId ) {
			case 'submit' :
				return handleFormSubmit(values) ;
			case 'cancel' :
				return onModalEnd({ button:'form_cancel' }) ;
			default :
				console.dir(values) ;
		}
	}
	
	const handleFormSubmit = (values) => {
        setLoading(true);
		let postValues = Object.assign({
			_url: window.location.href,
			acc_id: accId
		},values) ;
		createPostRequest("pay_getVendorForm",postValues)
			.then(response => {
				let ajaxData = response.data ;
				// console.dir(response) ;
				if( ajaxData.success === false ) {
                    setLoading(false);
                    setTransactionStep('E_alert');
                    setErrorMessage(translate("HomeModalPaidErrorHasOccurred") + "\n" + translate("HomeModalPaidPleaseTryAgainLater"));
					return ;
				}
                setLoading(false);
                setTransactionStep('2_vendor');
                setVendorFormConfig(ajaxData.form);
			})
			.catch(function (error) {
				console.log(error) ;
			});
	}

	// const handleFormCancel = (form) => {
	//     onModalEnd({ button:'form_cancel' }) ;
	// }

	// const handleFormDebug = (form) => {
	// 	//this.forceUpdate() ;
    //     setLoading(false);
    //     setFormValues({
    //         ...formValues,
    //         pay_amount: (formValues?.['pay_amount'] - 1),
    //         pay_comment: 'Commentaire...'
    //     })
	// }
	
	const handleErrorButton = (buttonId) => {
		switch( buttonId ) {
			case 'close' :
				onModalEnd({ button:'error_end' }) ;
				break ;
			default :
				break ;
		}
	}

    const renderModal = useCallback(( ) => {
        switch( transactionStep ) {
            case '1_form':
                return (<PayerForm formValues={formValues} onFormAction={handleFormAction} />) ;
            case '2_vendor':
                return (<PayerVendor formConfig={vendorFormConfig} onFormAction={handleFormAction} />) ;
            case 'E_alert' :
                return (<PayerAlert errorMessage={errorMessage} errorColor={errorColor} onErrorButton={handleErrorButton} />) ;
            default:
                return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        transactionStep, 
        formValues, 
        vendorFormConfig, 
        errorMessage, 
        errorColor
    ])

    return (
        <Modal 
            isOpen={isOpen} 
            toggle={props.toggleModal} 
            // size="lg"
            style={{ maxWidth: '960px', width: '100%' }}
        > 
            {
                loading 
                ? 
                    <div style={{display:'table', padding:'20px'}}>
                        <span style={{display:'table-cell', textAlign:'center', verticalAlign:'middle'}}>
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </span>
                    </div>
                :
                    <>
                        { renderModal() }
                    </>
            } 
        </Modal>
    );
}


// class Payer extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
// 		 loading: true,
//       records: null
//     }
//     //this.beginTransaction() ;
//   }


//   componentDidMount() {
// 	 // this.beginTransaction() ;
//   }

//   componentDidUpdate(prevProps) {
// 	  if( !this.props.isOpen ) {
// 			return ;
// 	  }
// 	  if( this.props.isOpen !== prevProps.isOpen ) {
// 		  return this.props.afterPay ? this.afterTransaction(this.props.afterPayCode) : this.beginTransaction() ;
// 	  }
//   }

// 	afterTransaction = (afterPayCode) => {
// 		this.setState({
// 			loading: true
// 		});
// 		let timerid = setTimeout(() => {
// 			this.afterTransactionDo(afterPayCode) ;
// 		}, 2000);
// 	}
// 	afterTransactionDo = (afterPayCode) => {
// 		createPostRequest("pay_getAfterpayStatus")
// 			.then(response => {
// 				let ajaxData = response.data ;
// 				if (ajaxData.success === false) {
// 					this.setState({
// 						loading: false,
// 						transactionStep: 'E_alert',
// 						errorMessage: 'Une erreur est survenue.\nVeuillez réessayer utérieurement'
// 					});
// 					return ;
// 				}
// 				let errorMessage, errorColor ;
// 				switch( afterPayCode ) {
// 					case 'OK' :
// 						switch( ajaxData.pay_status ) {
// 							case 'OK' :
// 								errorMessage = 'Votre paiement de '+ajaxData.pay_amount+' '+ajaxData.pay_currency+' est confirmé.\nNous vous en remercions.' ;
// 								errorColor = 'green' ;
// 								break ;
// 							default :
// 								errorMessage = 'Paiement en attente de réception. Veuillez vous reconnecter dans quelques minutes.' ;
// 								errorColor = 'green' ;
// 								break ;
// 						}
// 						break ;
// 					case 'KO' :
// 						switch( ajaxData.pay_status ) {
// 							case 'OK' :
// 								errorMessage = 'Votre paiement de '+ajaxData.pay_amount+' '+ajaxData.pay_currency+' est confirmé.\nNous vous en remercions.' ;
// 								errorColor = 'green' ;
// 								break ;
// 							case 'FAIL' :
// 								errorMessage = 'Echec du paiement. Merci de renouveler l\'opération ou de nous contacter.' ;
// 								errorColor = 'red' ;
// 								break ;
// 							case 'NONE' :
// 								errorMessage = 'Opération abandonnée.' ;
// 								errorColor = 'red' ;
// 								break ;
// 							default :
// 								errorMessage = 'Une erreur est survenue. Merci de nous contacter' ;
// 								errorColor = 'red' ;
// 								break ;
// 						}
// 						break ;
// 					default :
// 						errorMessage = 'Une erreur est survenue. Merci de nous contacter' ;
// 						errorColor = 'red' ;
// 						break ;
// 				}
// 				this.setState({
// 					loading: false,
// 					transactionStep: 'E_alert',
// 					errorMessage: errorMessage,
// 					errorColor: errorColor
// 				});
				
// 			})
// 			.catch(function (error) {
// 				console.log(error) ;
// 			});
		
// 	}
	
	
// 	beginTransaction = () => {
// 		this.setState({
// 			loading: true
// 		});
// 		createPostRequest("pay_pre_getAdr")
// 			.then(response => {
// 				let ajaxData = response.data ;
// 				if (ajaxData.success !== true) {
// 					this.setState({
// 						loading: false,
// 						transactionStep: 'E_alert',
// 						errorMessage: 'Une erreur est survenue.\nVeuillez réessayer utérieurement'
// 					});
// 					return ;
// 				}
// 				let formValues = {
// 					pay_amount: this.props.payAmount
// 				}
// 				Object.assign(formValues,ajaxData.data) ;
// 				this.setState({
// 					loading: false,
// 					transactionStep: '1_form',
// 					formValues: formValues
// 				})
// 			})
// 			.catch(function (error) {
// 				console.log(error) ;
// 			});
// 		/*
// 		let timerid = setTimeout(() => {
// 		}, 2000);
// 		*/
// 	}
	
// 	handleFormAction = (form,actionId,values) => {
// 		switch( actionId ) {
// 			case 'submit' :
// 				return this.handleFormSubmit(values) ;
// 			case 'cancel' :
// 				return this.props.onModalEnd({button:'form_cancel'}) ;
// 			default :
// 				console.dir(values) ;
// 		}
// 	}
	
// 	handleFormSubmit = (values) => {
// 		this.setState({
// 			loading: true
// 		});
// 		let postValues = Object.assign({
// 			_url: window.location.href,
// 			acc_id:this.props.accId
// 		},values) ;
// 		createPostRequest("pay_getVendorForm",postValues)
// 			.then(response => {
// 				let ajaxData = response.data ;
// 				console.dir(response) ;
// 				if (ajaxData.success === false) {
// 					this.setState({
// 						loading: false,
// 						transactionStep: 'E_alert',
// 						errorMessage: 'Une erreur est survenue.\nVeuillez réessayer utérieurement'
// 					});
// 					return ;
// 				}
// 				this.setState({
// 					loading: false,
// 					transactionStep: '2_vendor',
// 					vendorFormConfig: ajaxData.form
// 				});
				
// 			})
// 			.catch(function (error) {
// 				console.log(error) ;
// 			});
// 	}
// 	handleFormCancel = (form) => {
// 		this.props.onModalEnd({button:'form_cancel'}) ;
// 	}
// 	handleFormDebug = (form) => {
// 		//this.forceUpdate() ;
// 		this.setState({
// 				loading: false,
// 				formValues: {
// 					pay_amount: (this.state.formValues.pay_amount - 1),
// 					pay_comment: 'Commentaire...'
// 				}
// 			}) ;
// 	}
	
	
// 	handleErrorButton = (buttonId) => {
// 		switch( buttonId ) {
// 			case 'close' :
// 				this.props.onModalEnd({button:'error_end'}) ;
// 				break ;
				
// 			default :
// 				break ;
// 		}
// 	}

// 	render() {
// 		//console.log('Payer RENDER') ;
// 		return (
// 			<Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg"> {
// 				this.state.loading ? (
// 					<div style={{display:'table', padding:'20px'}}>
// 						<span style={{display:'table-cell', textAlign:'center', verticalAlign:'middle'}}>
// 							<Spinner style={{ width: '3rem', height: '3rem' }} />
// 						</span>
// 					</div>
// 				):(
// 					() => {
// 						switch (this.state.transactionStep) {
// 							case '1_form':
// 								return (<PayerForm formValues={this.state.formValues} onFormAction={this.handleFormAction} />) ;
// 							case '2_vendor':
// 								return (<PayerVendor formConfig={this.state.vendorFormConfig} onFormAction={this.handleFormAction} />) ;
// 							case 'E_alert' :
// 								return (<PayerAlert errorMessage={this.state.errorMessage} errorColor={this.state.errorColor} onErrorButton={this.handleErrorButton} {...this.props}  />) ;
// 							default:
// 								return null;
// 						}
// 					}
// 				)()
// 			} </Modal>
// 		);
// 	}
// }

export default Payer;