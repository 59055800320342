import React from "react";

import {Modal, ModalBody, ModalFooter, Button} from "reactstrap";

import { useTranslation } from "contexts";

function ConfirmModal(props) {
    const translate = useTranslation();

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalBody style={{textAlign: 'center'}}>
                { translate("NewMessageModal") }
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.validate} color="success">
                    { translate("MiscConfirm") }
                </Button>
                <Button onClick={props.toggle} color="danger">
                    { translate("MiscAbort") }
                </Button>
            </ModalFooter>
        </Modal>
  );
}

export default ConfirmModal ;