import React, { useEffect, useState } from "react";

import { Field, Formik } from "formik";
import { Col, FormGroup, Label } from "reactstrap";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const PaymentScheduler = ({ ...props }) => {
	const { amount, setEchErrors } = props;

	const [ nbEchMax, setNbEchMax ] = useState(0);
	const [ echFirstMinValue, setEchFirstMinValue ] = useState(0);
	const [ showSubEch, setSubEch ] = useState(false);
	const [ echCalc, setEchCalc ] = useState(false);
	const [ hasError, setError ] = useState(false);

    const translate = useTranslation();
	
    useEffect(() => {
		const renderEcheances = () => {
			let amountDue = amount;
			let echMax;
			if (amountDue >= 10000) {
				setNbEchMax(6);
				setEchFirstMinValue(amountDue * 0.3);
			} else if (amountDue >= 5000 && amountDue < 10000) {
				setNbEchMax(6);
				setEchFirstMinValue(amountDue * 0.25);
			} else if (amountDue >= 2000 && amountDue < 5000) {
				echMax = 6;
				setNbEchMax(6);
				setEchFirstMinValue(amountDue / echMax);
			} else if (amountDue >= 1000 && amountDue < 2000) {
				echMax = 4;
				setNbEchMax(4);
				setEchFirstMinValue(amountDue / echMax);
			} else if (amountDue >= 500 && amountDue < 1000) {
				echMax = 3;
				setNbEchMax(3);
				setEchFirstMinValue(amountDue / echMax);
			} else if (amountDue >= 200 && amountDue < 500) {
				echMax = 2;
				setNbEchMax(2);
				setEchFirstMinValue(amountDue / echMax);
			} else if (amountDue < 200) {
				echMax = 1;
				setNbEchMax(1);
				setEchFirstMinValue(amountDue / echMax);
			}

			setEchCalc(true);
		};

		renderEcheances();
	}, [amount, echFirstMinValue]);

	useEffect(() => {
		setEchErrors(hasError);
	}, [hasError, setEchErrors]);

	if (echCalc === false) return null;

	const validateNbEch = (value) => {
		let error;
		if (value % 1 !== 0) {
			setError(true);
			error = translate("NewMessageNumberOfDeadlinesMustBeInteger");
		}

		if (parseInt(value) <= 1) {
			setError(true);
			error = translate("NewMessageNumberOfDeadlinesMustBeGreaterThanOne");
		}
		if (parseInt(value) > nbEchMax) {
			setError(true);
			error = translate("NewMessageNumberOfDeadlinesCannotBeGreaterThan") + " " + nbEchMax;
		}
		return error;
	}

	return (
			<div className={"form"}>
				<Formik
                    initialValues={{
                    nbEchMax: nbEchMax,
                    nbEch: "",
                    echValue0:  new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(echFirstMinValue),
                    echValue1: "0"
                    }}
                >
					{({
						  setFieldValue,
						  validateForm
                    }) => (
                        <div>
                            <FormGroup row>
                                <Col xs="6">
                                    <Label>{ translate("NewMessageNumberOfDeadlines") }</Label>
                                </Col>
                                <Col xs="6">
                                    <Field 
                                        validate={validateNbEch} 
                                        component={InputWithFeedback} 
                                        type="text" 
                                        name="nbEch"
                                        onChange={(e) => {
                                            setFieldValue("nbEch", e.target.value);
                                            e.target.value = e.target.value.replace(",", ".");
                                            if (e.target.value > nbEchMax) return;
                                            if (Number.isInteger(parseInt(e.target.value)) === false) return;
                                            let nextValsTmp;
                                            if (e.target.value > 1) {
                                                nextValsTmp = amount - echFirstMinValue;
                                                nextValsTmp = nextValsTmp / (parseInt(e.target.value) - 1);
                                                setFieldValue("echValue1",new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 , maximumFractionDigits: 2}).format(nextValsTmp));
                                                setSubEch(true);
                                            } else setSubEch(false);
                                                let values = [] ;
                                                values["nbEch"] = e.target.value ;
                                                values["echValue0"] = echFirstMinValue ;
                                                values["echValue1"] = nextValsTmp ;
                                                validateForm().then(() => {
                                                    setError(false);
                                                    props.change(values);
                                                })
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            {
                                showSubEch === true 
                                ?
                                    <div>
                                        <FormGroup row key="echValue-0">
                                            <Col xs="3"/>
                                            <Col xs="3">
                                                <Label>{ translate("NewMessageFirstDeadline") }</Label>
                                            </Col>
                                            <Col xs="6">
                                                <Field component={InputWithFeedback} type="text" name="echValue0" disabled>
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row key="echValue-1">
                                            <Col xs="3"/>
                                            <Col xs="3">
                                                <Label>{ translate("NewMessageNextDeadlines") }</Label>
                                            </Col>
                                            <Col xs="6">
                                                <Field component={InputWithFeedback} type="text" name="echValue1" disabled>
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                :
                                    null
                            }
                        </div>
					)}
				</Formik>
			</div>

	);
}

export default PaymentScheduler;