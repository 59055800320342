import React from "react";

import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

import { useTranslation } from "contexts";

function AddCommentModal(props) {
    const translate = useTranslation();

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader>
                <strong>{ translate("NewMessageNewComment") }</strong>
            </ModalHeader>
            <ModalBody>
                <FormGroup row>
                <Col xs="8">
                    <Label>{ translate("NewMessageEnterYourComment") }</Label>
                </Col>
                </FormGroup>
                <FormGroup row>
                <Col xs="12">
                    <Input type="textarea" onChange={(e) => props.onChange(e)} defaultValue={props.content}></Input>
                </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={props.toggle}>{ translate("MiscConfirm") }</Button>
                <Button color="danger" onClick={() => props.onChange(null)}>{ translate("MiscAbort") }</Button>
            </ModalFooter>
        </Modal>
  );
}

export default AddCommentModal ;