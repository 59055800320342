import React from 'react';

import { Button, ButtonGroup, Col, FormGroup, Label, Row } from "reactstrap";
import { Field } from "formik";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

function UploadComponent(props) {
    const translate = useTranslation();

    return (
        <Col xs="6">
            <FormGroup row>
                <Col xs="12">
                <Label className={props.msgRequired === true ? "control-label" : ""}>{ translate("MiscMessage") }</Label>
                <Field component={InputWithFeedback} style={{height: "250px"}}type="textarea" name="comment"/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col xs="12">
                    <Label htmlFor="file-multiple-input">{ translate("MiscSubmitDocument") }</Label>
                </Col>
                <Col xs="12">
                    <Label for="file-multiple-input" className="m-0">{ translate("MiscSelectFile") }</Label>
                    <span className="mx-2">|</span>
                    {
                        props.files.length === 0 
                        ?
                            <Label for="file-multiple-input" className="m-0">{ translate("MiscNoFileSelected") }</Label>
                        :
                            <Label for="file-multiple-input" className="m-0">{ props.files.length + " " + translate("MiscFileSelected") }</Label>
                    }
                    <Field 
                        style={{ height: "1px", padding: "0", visibility: "hidden" }} 
                        component={InputWithFeedback} 
                        type="file" 
                        id="file-multiple-input"
                        name="file-multiple-input" 
                        onChange={props.addFiles} 
                        multiple
                    />
                </Col>
            </FormGroup>
            {
                props.files.map((file, i) => (
                    <Row className="mt-2" key={"file-input-" + i}>
                        <Col key={"fileInputCol-" + i} xs="8">
                            <Field component={InputWithFeedback} type="text" name={"fileInput-" + i}
                                value={file.newName !== "" ? file.newName : file.name}
                                onChange={(e) => {
                                    if (e.target.value !== "" && e.target.value !== null) {
                                        props.setFileNewName(e.target.value, i);
                                        props.setFieldValue("fileInput-" + i, e.target.value);
                                    }
                                }}>
                            </Field>
                        </Col>
                        <Col xs="3">
                            <ButtonGroup className="mr-2">
                            <Button size="sm" style={{minWidth: '0'}} color="primary"
                                    onClick={() => {
                                        props.setCurrentFile(i);
                                        props.setShowModal(true)
                                    }}>
                                <i className="fa fa-info fa-lg"/>
                            </Button>
                            <Button size="sm" style={{minWidth: '0'}} color="danger" onClick={() => {
                                const newFiles = [...props.files];
                                const index = newFiles.indexOf(file);
                                if (index > -1) {
                                newFiles.splice(index, 1);
                                props.setFiles(newFiles);
                                if (index === props.currentFile){
                                    props.setCurrentFile(null) ;
                                }
                                }
                            }}>
                                <i className="fa fa-close fa-lg"/>
                            </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                ))
            }
        </Col>
  );

}

export default UploadComponent;