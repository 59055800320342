import {Modal, ModalBody, ModalFooter, Button} from "reactstrap";
import React from "react";

function ErrorModal(props) {
  return (
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalBody style={{textAlign: 'center'}}>
          {props.error}
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.toggle} color="primary">
            OK
          </Button>
        </ModalFooter>
      </Modal>
  )

}
export default ErrorModal ;
