import React, { useMemo, useRef } from 'react';

import {
  Button,
  Col,
  Container,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardHeader,
  CardBody,
  Row
} from 'reactstrap';
import { Field, Form, Formik } from "formik";

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const PayerForm = ({ ...props }) => {
    const { formValues, onFormAction } = props;

    const translate = useTranslation();
    const formikRef = useRef();

    const labelWidth = useMemo(() => ('120px'), []) ;

    const fields = useMemo(() => ([
        'pay_amount',
        'pay_comment',
        'adr_nom',
        'adr_adr1',
        'adr_adr2',
        'adr_cp',
        'adr_ville',
        'adr_pays',
        'adr_tel',
        'adr_email'
    ]), []) ;

    const fieldsMandatory = useMemo(() => ([
        'adr_nom',
        'adr_adr1',
        'adr_cp',
        'adr_ville',
        'adr_pays',
        'adr_tel',
        'adr_email'
    ]), []) ;
    
    const isEmpty = (str) => {
        return (!str || 0 === str.length);
    }

    const getFormik = () => {
        return formikRef.current ;
    }
    
    const getInitialValues = () => {
        let initialValues = {} ;
        fields.forEach(element => {
            if( formValues[element] ) {
                initialValues[element] = formValues[element] ;
                return ;
            }
            initialValues[element] = '' ;
        }) ;
        return initialValues ;
    }
        
    const handleButton = (actionId) => {
        switch( actionId ) {
            case 'submit' :
                return getFormik().submitForm() ;
            case 'reset' :
                return getFormik().resetForm() ;
            default :
                break ;
        }
        onFormAction(this,actionId,getFormik().values) ;
    }

    const handleSubmit = (values) => {
        onFormAction(this,'submit',values) ;
    }
        
    const doFormikValidate = (values) => {
        const errors = {};
        if( (values.pay_amount > 0) && (values.pay_amount <= formValues['pay_amount']) ) {} else {
            errors.pay_amount =  translate("HomeAmount") + " " + translate("MiscIncorrect").toLowerCase() ;
        }
        /*
        if( !(RegExp(/^(0|\+33)[0-9]{9}$/).test(values.adr_tel)) ) {
            errors.adr_tel = 'Numéro de téléphone incorrect';
        }
        */
        if( !(RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.adr_email)) ) {
            errors.adr_email =  translate("MiscEmail") + " " + translate("MiscIncorrect").toLowerCase() ;
        }
        fieldsMandatory.forEach( mkey => {
            if (isEmpty(values[mkey])) {
                errors[mkey] = translate("MiscToInform") ;
            }
        });
        return errors;
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validate={doFormikValidate}
            onReset={() => {}}
        >
            {props => (
                <Form>
                    <ModalHeader>
                        <strong>{ translate("HomeModalPaidFormAboutMakePayment") }</strong>
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardHeader>
                                <strong>{ translate("HomeModalPaidFormContactDetails") }</strong>
                                &nbsp;&nbsp;&nbsp;
                                <small>{ translate("HomeModalPaidFormInformationMatchIdentityPayer") }</small>
                            </CardHeader>
                            <CardBody>
                                <Container>
                                    <FormGroup row>
                                        <div style={{ width: labelWidth }}>
                                            <Label className="div-form-label">
                                                { translate("NewMessageLastname") }
                                            </Label>
                                        </div>
                                        <Col>
                                            <Field component={InputWithFeedback} type="text" name="adr_nom"></Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <div style={{ width: labelWidth }}>
                                            <Label className="col-form-label">
                                                { translate("MiscAddress") }
                                            </Label>
                                        </div>
                                        <Col>
                                            <Field component={InputWithFeedback} type="text" name="adr_adr1"></Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <div style={{ width: labelWidth }}>
                                            <Label className="col-form-label">
                                                { translate("NewMessageAdditionalInformation") }
                                            </Label>
                                        </div>
                                        <Col>
                                            <Field component={InputWithFeedback} type="text" name="adr_adr2"></Field>
                                        </Col>
                                    </FormGroup>
                                    <Row>
                                        <Col xs={4}>
                                            <FormGroup row>
                                                <div style={{ width: labelWidth }}>
                                                    <Label className="col-form-label">
                                                        { translate("NewMessageZipCode") }
                                                    </Label>
                                                </div>
                                                <Col>
                                                    <Field component={InputWithFeedback} type="text" name="adr_cp" ></Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4}>
                                            <FormGroup row>
                                                <div style={{ width: '50px' }}>
                                                    <Label className="col-form-label">
                                                        { translate("NewMessageCity") }
                                                    </Label>
                                                </div>
                                                <Col>
                                                    <Field component={InputWithFeedback} type="text" name="adr_ville"></Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4}>
                                            <FormGroup row>
                                                <div style={{ width: '70px' }}>
                                                    <Label className="col-form-label">
                                                        { translate("NewMessageCountry") }
                                                    </Label>
                                                </div>
                                                <Col>
                                                    <Field component={InputWithFeedback} type="text" name="adr_pays" readOnly></Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup row>
                                                <div style={{ width:labelWidth }}>
                                                    <Label className="col-form-label">
                                                        { translate("MiscTelephoneNumber") }
                                                    </Label>
                                                </div>
                                                <Col>
                                                    <Field component={InputWithFeedback} type="text" name="adr_tel"></Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup row>
                                                <div style={{ width: '80px' }}>
                                                    <Label className="col-form-label">
                                                        { translate("MiscEmail") }
                                                    </Label>
                                                </div>
                                                <Col>
                                                    <Field component={InputWithFeedback} type="text" name="adr_email"></Field>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <strong>{ translate("MiscPayment") }</strong>
                                &nbsp;&nbsp;&nbsp;
                                <small>{ translate("HomeModalPaidFormPreSelectedAmount") }</small>
                            </CardHeader>
                            <CardBody>
                                <Container>
                                    <FormGroup row>
                                        <Label xs="3">
                                            { translate("MiscAmountToBePaid") } (€)
                                        </Label>
                                        <Col xs="3">
                                            <Field component={InputWithFeedback} type="text" name="pay_amount"></Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label xs="3">
                                            { translate("NewMessageComment") }&nbsp;&nbsp;<small>({ translate("MiscOptionnal").toLowerCase() })</small>
                                        </Label>
                                        <Col>
                                            <Field component={InputWithFeedback} type="textarea" name="pay_comment"></Field>
                                        </Col>
                                    </FormGroup>
                                </Container>
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleButton("submit")}>{ translate("MiscContinue") }</Button>
                        <Button color="primary" style={{ display:'none' }} onClick={() => handleButton("reset")}>{ translate("MiscReset") }</Button>
                        <Button color="danger"  onClick={() => handleButton("cancel")}>{ translate("MiscAbort") }</Button>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
}

// class PayerForm extends Component {
//   constructor(props) {
//     super(props);
// 	 this.formikRef = React.createRef() ;
//   }


  
//   getFormik() {
// 	  return this.formikRef.current ;
//   }

	
// 	testFn = () => {
// 		/*
// 		var thisEl = ReactDOM.findDOMNode(this) ;
		
// 		let mkey = 'nom' ;
// 		console.dir(thisEl.querySelector('[name=' + mkey + ']')) ;
// 		*/
// 	}
	
// 	getInitialValues() {
// 		let initialValues = {} ;
// 		fields.forEach(element => {
// 			if( this.props.formValues[element] ) {
// 				initialValues[element] = this.props.formValues[element] ;
// 				return ;
// 			}
// 			initialValues[element] = '' ;
// 		}) ;
// 		return initialValues ;
// 	}
	
// 	handleButton(actionId) {
// 		switch( actionId ) {
// 			case 'submit' :
// 				return this.getFormik().submitForm() ;
// 			case 'reset' :
// 				return this.getFormik().resetForm() ;
			
// 			default :
// 				break ;
// 		}
// 		this.props.onFormAction(this,actionId,this.getFormik().values) ;
// 	}
// 	handleSubmit = (values) => {
// 		this.props.onFormAction(this,'submit',values) ;
// 	}
	
	
// 	doFormikValidate = (values) => {
// 		const errors = {};
// 		if( (values.pay_amount > 0) && (values.pay_amount <= this.props.formValues['pay_amount']) ) {} else {
// 			errors.pay_amount = 'Montant incorrect';
// 		}
// 		/*
// 		if( !(RegExp(/^(0|\+33)[0-9]{9}$/).test(values.adr_tel)) ) {
// 			errors.adr_tel = 'Numéro de téléphone incorrect';
// 		}
// 		*/
// 		if( !(RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.adr_email)) ) {
// 			errors.adr_email = 'Email incorrect';
// 		}
// 		fieldsMandatory.forEach( mkey => {
// 			if (isEmpty(values[mkey])) {
// 				errors[mkey] = 'A renseigner';
// 			}
// 		});
// 		return errors;
// 	}

//   render() {
//     return (
// 			<Formik
// 			 innerRef={this.formikRef}
//           initialValues={this.getInitialValues()}
//           onSubmit={this.handleSubmit}
//           validate={this.doFormikValidate}
//           onReset={() => {}}
//         >
//           {props => (
//           <Form>
//             <ModalHeader>
//               <strong>Vous êtes sur le point de procéder au paiement</strong>
//             </ModalHeader>
//             <ModalBody>
// 					<Card>
// 						<CardHeader>
// 							<strong>Coordonnées</strong>
// 							&nbsp;&nbsp;&nbsp;
// 							<small>Les informations doivent correspondre à l'identité du payeur</small>
// 						</CardHeader>
// 						<CardBody>
// 							<Container>
// 							<FormGroup row>
// 								<Label className="col-form-label" style={{width:firstLabelWidth}}>
// 									Nom
// 								</Label>
// 								<Col>
// 									<Field component={InputWithFeedback} type="text" name="adr_nom"></Field>
// 								</Col>
// 							</FormGroup>
// 							<FormGroup row>
// 								<Label className="col-form-label" style={{width:firstLabelWidth}}>
// 									Adresse
// 								</Label>
// 								<Col>
// 									<Field component={InputWithFeedback} type="text" name="adr_adr1"></Field>
// 								</Col>
// 							</FormGroup>
// 							<FormGroup row>
// 								<Label className="col-form-label" style={{width:firstLabelWidth}}>
// 									Complément
// 								</Label>
// 								<Col>
// 									<Field component={InputWithFeedback} type="text" name="adr_adr2"></Field>
// 								</Col>
// 							</FormGroup>
// 							<Row>
// 								<Col xs="4">
// 									<FormGroup row>
// 										<Label className="col-form-label" style={{width:firstLabelWidth}}>
// 											Code Postal
// 										</Label>
// 										<Col>
// 											<Field component={InputWithFeedback} type="text" name="adr_cp" ></Field>
// 										</Col>
// 									</FormGroup>
// 								</Col>
// 								<Col xs="6">
// 									<FormGroup row>
// 										<Label className="col-form-label">
// 											Ville
// 										</Label>
// 										<Col>
// 											<Field component={InputWithFeedback} type="text" name="adr_ville"></Field>
// 										</Col>
// 									</FormGroup>
// 								</Col>
// 								<Col xs="2">
// 									<FormGroup row>
// 										<Label className="col-form-label">
// 											Pays
// 										</Label>
// 										<Col>
// 											<Field component={InputWithFeedback} type="text" name="adr_pays" readOnly></Field>
// 										</Col>
// 									</FormGroup>
// 								</Col>
// 							</Row>
// 							<Row>
// 								<Col xs="6">
// 									<FormGroup row>
// 										<Label className="col-form-label" style={{width:firstLabelWidth}}>
// 											Téléphone
// 										</Label>
// 										<Col>
// 											<Field component={InputWithFeedback} type="text" name="adr_tel"></Field>
// 										</Col>
// 									</FormGroup>
// 								</Col>
// 								<Col xs="6">
// 									<FormGroup row>
// 										<Label className="col-form-label">
// 											Email
// 										</Label>
// 										<Col>
// 											<Field component={InputWithFeedback} type="text" name="adr_email"></Field>
// 										</Col>
// 									</FormGroup>
// 								</Col>
// 							</Row>
// 							</Container>
// 						</CardBody>
// 					</Card>
					
// 					<Card>
// 						<CardHeader>
// 							<strong>Paiement</strong>
// 							&nbsp;&nbsp;&nbsp;
// 							<small>Le montant pré-sélectionné correspond au total de la créance</small>
// 						</CardHeader>
// 						<CardBody>
// 							<Container>
// 								<FormGroup row>
// 									<Label xs="3">
// 										Montant à payer (€)
// 									</Label>
// 									<Col xs="3">
// 										<Field component={InputWithFeedback} type="text" name="pay_amount"></Field>
// 									</Col>
// 								</FormGroup>
// 								<FormGroup row>
// 									<Label xs="3">
// 										Commentaire&nbsp;&nbsp;<small>(optionnel)</small>
// 									</Label>
// 									<Col>
// 										<Field component={InputWithFeedback} type="textarea" name="pay_comment"></Field>
// 									</Col>
// 								</FormGroup>
// 							</Container>
// 						</CardBody>
// 					</Card>
//             </ModalBody>
//             <ModalFooter>
//               <Button color="primary" onClick={()=>{this.handleButton("submit");}}>Continuer</Button>
//               <Button color="primary" style={{display:'none'}} onClick={()=>{this.handleButton("debug");}}>Test action</Button>
//               <Button color="primary" style={{display:'none'}} onClick={()=>{this.handleButton("reset");}}>Reset</Button>
//               <Button color="danger"  onClick={()=>{this.handleButton("cancel");}}>Annuler</Button>
//             </ModalFooter>
//           </Form>
//           )}
//         </Formik>
//     );
//   }
// }

export default PayerForm ;
