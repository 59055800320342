import React from "react";

import { Field } from "formik";
import { Col, FormGroup, Label } from 'reactstrap';

import { InputWithFeedback } from "components";
import { useTranslation } from "contexts";

const ContactDetailBlock = ({ variant }) => {
    const translate = useTranslation();

    return (
        <>
            <FormGroup row>
                <Col xs="6"/>
                <Col xs="6">
                    <Field component={InputWithFeedback} disabled type="text" name="name"/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col xs="6">
                    <Label>{ translate("MiscContactDetails") }</Label>
                </Col>
                <Col xs="6">
                    <Field component={InputWithFeedback} type="text" name="newName"/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col xs="6">
                    <Label className="control-label">{ translate("MiscTelephoneNumber") }</Label>
                </Col>
                <Col xs="6">
                    <Field component={InputWithFeedback} type="text" name="tel"/>
                </Col>
            </FormGroup>
            {
                variant
                ?
                    <>
                        <FormGroup row>
                            <Col xs="6">
                                <Label className="control-label">{ translate("NewMessageDate") }</Label>
                            </Col>
                            <Col xs="6">
                                <Field component={InputWithFeedback} type="date" name="prefDay"/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Col xs="6">
                                <Label>{ translate("NewMessageTimeSlot") }</Label>
                                <br/>
                                <small style={{fontStyle: "italic"}}>{ translate("NewMessageEndeavorToContactYou") }</small>
                            </Col>
                            <Col xs="6">
                                <Field component={InputWithFeedback} type="select" as="select" name="prefHour">
                                <option value="8h-12h">{ translate("NewMessage8To12") }</option>
                                <option value="12h-14h">{ translate("NewMessage12To14") }</option>
                                <option value="14h-18h">{ translate("NewMessage14To18") }</option>
                                <option value="18h-20h">{ translate("NewMessage18To20") }</option>
                                </Field>
                            </Col>
                        </FormGroup>
                    </>
                :
                    <FormGroup row>
                        <Col xs="6">
                            <Label>{ translate("MiscContactPreferences") }</Label>
                        </Col>
                        <Col xs="6">
                            <Field component={InputWithFeedback} type="text" name="prefContact"/>
                        </Col>
                    </FormGroup>
            }
            <FormGroup row>
                <Col xs="6">
                    <Label className="control-label">{ translate("MiscEmail") }</Label>
                </Col>
                <Col xs="6">
                    <Field component={InputWithFeedback} type="text" name="email"/>
                </Col>
            </FormGroup>
        </>
    );
}

export default ContactDetailBlock;