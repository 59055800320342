import React, { useEffect, useState } from 'react';

import { instance as axios } from "./Utils/axiosConfig";
import { useLocation } from "react-router-dom";
import qs from 'qs';

import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    InputGroup,
    //   InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';

import { useAuth, useTranslation } from "contexts";

const useFormInput = initialValue => {
    const [ value, setValue ] = useState(initialValue);
    
    const handleChange = e => {
        setValue(e.target.value);
    }

    return {
        value,
        setValue,
        onChange: handleChange,
    }
}
// Se connecter via url exemple: http://10.39.10.209:3000/#/login?base=hvs&usr=M01-C41007085&pwd=e657fdd5ade549f276c079f4f5280f63
// base=veo/hvs, usr=numCli, pwd=MD5 numCli
const Login = ({ ...props }) => {
    const [ loading, setLoading ] = useState(false);
    const [ hasLoginParams, setLoginParams ] = useState(false) ;
    const [ loginSuccess, setSuccess ] = useState(null) ;
    const [ error, setError ] = useState(null);

    const location = useLocation();
    const { auth, setAuth } = useAuth();
    const translate = useTranslation();

    const username = useFormInput('');
    const password = useFormInput('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search) ;

        if (searchParams.get("usr") !== null && searchParams.get("pwd") !== null){
            setLoginParams(true) ;
            let usernameSet = searchParams.get("usr") ;
            if( searchParams.get("base") !== null ) {
                usernameSet += '@'+searchParams.get("base") ;
            }
            
            let passwordSet = searchParams.get("pwd") ;
            
            username.setValue(usernameSet) ;
            password.setValue(passwordSet) ;
        }
    }, [password, username])

    const handleLogin = (fromLink) => {
        let valueUsername = username.value,
            valuePassword = password.value ;
        let valueBase ;
        
        if (fromLink === true){
            setSuccess("START") ;
        }

        let config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                "Access-Control-Allow-Headers": "Content-Type"
            }
        } ;
        let arrUsername = valueUsername.split('@') ;

        if( arrUsername.length===2 ) {
            valueUsername = arrUsername[0] ;
            valueBase = arrUsername[1] ;
        }

        if (valueUsername === "" || valuePassword === ""){
            setError("Tous les champs devraient être remplis");
            return ;
        }

        setLoading(true);

        axios.post('/login', qs.stringify({
            username: valueUsername,
            password: valuePassword,
            base: valueBase
        }), config)
            .then(resp => resp.data)
            .then(resp => {
                setLoading(resp.success === true);
                setError(resp.success === true ? null : "Nom d'utilisateur ou mot de passe incorrect") ;
                setSuccess(resp.success === true && fromLink === true ? "DONE" : fromLink === true ? "FAIL" : null);
                setAuth({ ...auth, isAuth: resp.success });
            })
            .catch(err => {
                setLoading(false);
            });
    } ;

    const loadingScreen = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

    if (hasLoginParams === true && loginSuccess === null){
        handleLogin(true) ;
    }
    if (loginSuccess === "START"){
        return loadingScreen() ;
    }

    return (
        <>
            {
                auth.isAuth !== true
                ?
                    <Container style={{ height: 'calc(100% - 30px)' }}>
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col md="8">
                                <CardGroup>
                                    <Card className="p-4">
                                        <CardBody>
                                            <Form>
                                                <h1>{ translate("LogAuthentification") }</h1>
                                                <p className="text-muted">{ translate("LogLoginToYourAccount") }</p>
                                                <InputGroup className="mb-3">
                                                    {/* <InputGroupAddon addonType="prepend"> */}
                                                    <InputGroupText>
                                                        <i className="icon-user"/>
                                                    </InputGroupText>
                                                    {/* </InputGroupAddon> */}
                                                    <Input type="text" invalid={error !== null} placeholder={ translate("LogUsername") } value={username.value}
                                                            onChange={username.onChange} autoComplete="new-username" />
                                                    <FormFeedback>{error}</FormFeedback>
                                                </InputGroup>
                                                <InputGroup className="mb-4">
                                                    {/* <InputGroupAddon addonType="prepend"> */}
                                                    <InputGroupText>
                                                        <i className="icon-lock"/>
                                                    </InputGroupText>
                                                    {/* </InputGroupAddon> */}
                                                    <br />
                                                    <Input type="password" invalid={error !== null} placeholder={ translate("LogPassword") } value={password.value}
                                                            onChange={password.onChange} autoComplete="new-password" />
                                                    <FormFeedback>{error}</FormFeedback>
                                                </InputGroup>
                                                <Row>
                                                    <Col xs="6">
                                                        <Button 
                                                            color="primary" 
                                                            className="px-4" 
                                                            value={loading ? 'Loading...' : 'Login'}
                                                            onClick={handleLogin} 
                                                            disabled={loading}
                                                        >
                                                            { translate("LogLogin") }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                :
                    null
            }
        </>
    );
}

export default Login;